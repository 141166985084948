<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Пригласить пользователя
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="12">
          <b-form-group
            class="form-group-el-select"
            label="Название"
          >
            <b-form-input
              v-model.trim="$v.form.name.$model"
              type="text"
              :state="chkState('name')"
              autocomplete="false"
              autofocus
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="form-group-el-select"
            label="Описание"
          >
            <b-form-textarea
              v-model.trim="$v.form.description.$model"
              :state="chkState('description')"
              type="text"
              autocomplete="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="form-group-el-select"
            label="Сортировка"
          >
            <b-form-input
              v-model.trim="$v.form.sortPosition.$model"
              :state="chkState('sortPosition')"
              type="number"
              autocomplete="false"
              @wheel="$event.target.blur()"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            id="preview"
            label="Превью"
          >
            <b-form-file
              v-model.trim="$v.form.preview.$model"
              v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
              :state="chkState('preview')"
              accept=".jpg, .jpeg, .png"
              class="mt-3"
              placeholder="Файл не выбран"
              browse-text="Выбрать"
              drop-placeholder="Перетащите файл сюда"
              @input="uploadFile('preview')"
            />
            <div
              v-if="form.preview && form.preview.file"
              class="mt-3"
            >
              Загруженный файл:
              <a
                :href="'/files/'+form.preview.file.guid"
                target="_blank"
                @click.prevent="downloadFile(form.preview.file)"
              >{{ form.preview.file.originalName }}</a>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            id="video"
            label="Видео"
          >
            <b-form-file
              v-model.trim="$v.form.video.$model"
              v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
              :state="chkState('video')"
              accept=".mp4"
              class="mt-3"
              placeholder="Файл не выбран"
              browse-text="Выбрать"
              drop-placeholder="Перетащите файл сюда"
              @input="uploadFile('video')"
            />
            <div
              v-if="form.video && form.video.file"
              class="mt-3"
            >
              Загруженный файл:
              <a
                :href="'/files/'+form.video.file.guid"
                target="_blank"
                @click.prevent="downloadFile(form.video.file)"
              >{{ form.video.file.originalName }}</a>
              <video-player
                ref="videoPlayer"
                :options="playerOptions"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="form-group-el-select"
            label="Опубликован"
          >
            <b-form-checkbox
              v-model="form.published"
              autocomplete="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :class="{'loading' : loading}"
          :disabled="loading"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Vue from 'vue';
import 'video.js/dist/video-js.css';
import notifications from '@/components/mixins/notifications';
import {
  customerCreateVideoLesson,
  documentCreate,
  downloadFile,
} from '@/services/api';
import {videoPlayer} from 'vue-video-player';
import {validationMixin} from 'vuelidate';
import {maxLength, required} from 'vuelidate/lib/validators';


export default {
  name: 'CustomerVideoLessonAdd',
  components: {
    videoPlayer,
  },
  mixins: [validationMixin, notifications],
  validations: {
    form: {
      name: {
        required,
      },
      video: {
        required,
      },
      sortPosition: {
        required,
      },
      description: {
      },
      published: {
      },
      preview: {
        required,
      },
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      form: {
        name: '',
        description: '',
        sortPosition: 50,
        preview: null,
        video: null,
        published: true,
      },
    };
  },
  computed: {
    videoURl: function() {
      return process.env.VUE_APP_API_URL;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    playerOptions: function() {
      return {
        height: '360',
        autoplay: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          // mp4
          src: this.videoURl+'/api/files/' + this.form.video.file.guid + '/download',
        }],
      };
    },
  },
  mounted() {
    let domainInclude = this.videoURl; // Recieve from Component
    let authToken = window.localStorage.getItem('token'); // Recieve from Component

    self.addEventListener('install', (event) => {
      const params = new URL(location);
      domainInclude = params.searchParams.get('include');
      authToken = params.searchParams.get('token');
      const installCompleted = Promise.resolve()
        .then(() => {});
      event.waitUntil(installCompleted);
    });

    self.addEventListener('activate', (event) => {
      event.waitUntil(
        self.clients.claim(),
        caches.keys().then((cacheNames) => {
          return Promise.all(
            cacheNames.map((cache) => {
              if (cache !== cacheName) {
                return caches.delete(cache);
              }
            }),
          );
        }));
    });

    // This function is implement from the guide of @Ashesh3
    self.addEventListener('fetch', (event) => {
      if (event.request.url.includes(domainInclude)) {
        event.respondWith(customHeaderRequestFetch(event));
      }
    });

    function customHeaderRequestFetch(event) {
      const newRequest = new Request(event.request, {
        mode: 'cors',
        credentials: 'omit',
        // also supports partial content (seeking)
        headers: {
          range:
              event.request.headers.get('range') !== undefined ?
                event.request.headers.get('range') :
                '0-',
          Authorization: authToken,
        },
      });
      return fetch(newRequest);
    }
  },
  methods: {
    downloadFile,
    uploadFile(key) {
      console.log(process.env);
      const file = this.form[key];
      this.getBase64(file).then((data) => {
        this.loading = true;
        const upload = {
          base64file: data,
          originalName: file.name,
        };
        this.documentCreate(key, upload);
      });
    },
    async documentCreate(key, upload) {
      this.loading = true;
      const response = await documentCreate(upload);
      if (response && response.status === 201) {
        this.form[key] = response.data;
        this.showSuccess('Документ загружен');
      }
      this.loading = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async onSubmit() {
      if (this.validate()) {
        const sendData = {...this.form};
        this.loading = true;
        const response = await customerCreateVideoLesson(sendData);
        if (response && response.status === 201) {
          this.showSuccess('Видеоурок ' + this.form.name + ' добавлен');
          this.$router.push({path: '/customers/video-lessons'});
        }
        this.loading = false;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>

</style>
