<script>
import {DatePicker} from 'element-ui';
import moment from 'moment';

const ElDatePickerInput = {
  name: 'ElDatePickerInput',
  extends: DatePicker,
  data() {
    return {
      userData: '',
    };
  },
  methods: {
    inputDate() {
      this.userInput = this.userInput.replace(/^[a-zA-Zа-яА-Яё]+/g, '');
      switch (this.userInput.length) {
      case 2:
        // Day validation
        const day = Number(this.userInput);
        (day > 31) ? this.userInput = this.userInput.slice(0, 1) : (this.userInput.indexOf('.') == -1) && (this.userInput += '.');
        break;
      case 5:
        // Month validation
        const month = Number(this.userInput.slice(3, 5));
        (month > 12) ? this.userInput = this.userInput.slice(0, 4) : ((this.userInput.match(/.+/g) || []).length == 1) && (this.userInput += '.');
        break;
      case 10:
        // for 'datetime' type - endline is whitespace
        (this.type == 'datetime' && this.userInput.indexOf(' ') == -1) && (this.userInput += ' ');
        this.userData = this.userInput;
        break;
      case 11:
        // for 'date' type - block an input by delete string symbol characters after date string length > 10
        (this.type == 'date') && (this.userInput = this.userInput.slice(0, 10));
        break;
      case 13:
        // Hour validation
        const hour = this.userInput.slice(11, 13);
        (hour > 23) ? this.userInput = this.userData : this.userData = this.userInput;
        (this.userInput.indexOf(':') == -1) && (this.userInput = this.userInput + ':');
        break;
      case 16:
        // Mins validation
        const min = this.userInput.slice(14, 16);
        (min > 59) && (this.userInput = this.userData);
        this.userInput = this.userInput.replace(/(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3 $4:$5');
        this.userData = this.userInput;
        break;
      default:
        break;
      }
      if (this.userInput.length == 10 && this.type == 'date') {
        this.userInput = this.userInput.slice(10, 0);
        this.userInput = this.userData;
      }
      if (this.userInput.length == 17 && this.type == 'datetime') {
        this.userInput = this.userInput.slice(17, 0);
        this.userInput = this.userData;
      }
    },
    formatDate() {
      if (this.userData.length == 10 && this.type == 'date') {
        this.userData = moment(this.userData, 'DD.MM.YY').format('DD.MM.YYYY');
      }
      if (this.userData.length == 17 && this.type == 'datetime') {
        this.userData = moment(this.userData, 'DD.MM.YY HH:mm').format('DD.MM.YYYY HH:mm');
      }
    },
    setDate() {
      this.userInput = this.userData;
    },
  },
  mounted() {
    this.$el.addEventListener('input', this.inputDate);
    this.$el.addEventListener('change', this.formatDate);
    this.$el.addEventListener('blur', this.setDate);
  },
};

export default ElDatePickerInput;
</script>
