<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Группа ответственных"
              >
                <b-form-input
                  v-model="filter.query"
                  class="form-control"
                  name="query"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  @input="updateFilter('query')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Пользователь"
              >
                <el-select-clearable
                  v-model="filter.agent"
                  class="form-control"
                  name="agent"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersUsersList"
                  :loading="loading"
                  @input="updateFilter('agent')"
                  @clear="customersUsersList"
                >
                  <el-option
                    v-for="item in users"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Менеджеры СТЛ
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="$store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="'/customer/stl/id' + props.row.id"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ElSelectClearable from '@/components/ElSelectClearable.vue';
import moment from 'moment';
import {customerRouteOwnersList, customersUsersList} from '../../services/api';
import {Event, ServerTable} from 'vue-tables-2';
import {queryToUrl} from '../../services/http';
import Vue from 'vue';

Vue.use(ServerTable);

export default {
  name: 'STLList',
  components: {
    ElSelectClearable,
  },
  data: function() {
    return {
      lastRefreshTime: null,
      managers: [],
      users: [],
      filter: {
        name: null,
        user_id: null,
        page: 1,
        limit: 100,
      },
      loading: false,
      columns: [
        'name',
        'agent.fullname',
        'actions',
      ],
      data: [],
      options: {
        customFilters: [
          'query',
          'agent',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customerRouteOwnersList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'name': 'Название',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 100,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  mounted() {
    // this.customersRouteRead(this.$store.state.user.ownerId, this.flightId);
    this.customersUsersList({});
  },
  methods: {
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customerRouteOwnersList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.manager.length > 0) {
        this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const selectedManagers = {};
      this.filter.manager.map((item, index) => {
        selectedManagers['id[' + index + ']'] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        console.log(field);
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    async customersUsersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersUsersList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.users = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.fullname+` (${item.username})`,
          };
        });
      }
      if (this.filter.users) {
        await this.customersUsersSelectedList();
      }
      this.loading = false;
    },
    async customersUsersSelectedList() {
      this.loading = true;
      const selectedManagers = {};
      this.filter.users.map((item, index) => {
        selectedManagers['id[' + index + ']'] = item;
      });
      const response = await customersUsersList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.users = this.users.filter((x) => x.value !== item.id);
          this.users.unshift({
            value: item.id,
            text: item.fullname+` (${item.username})`,
          });
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
