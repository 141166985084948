import axios from 'axios';
import store from '../store';
import qs from 'qs';
import router from '../router';
import notifications from '../components/mixins/notifications';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    // Accept: "application/json"
  },
});

http.interceptors.request.use(
  (request) => {
    if (window.localStorage.getItem('token')) {
      const token = window.localStorage.getItem('token');
      request.headers.Authorization = `bearer ${token}`;
    }
    return request;
  },
  /* nvseslint-disable no-console */
  (error) => {
    console.error('[DEV Request]', error.response);
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    if (response && response.data != null && response.data.status === 401) {
      console.log('401');
      axios
        .get('/api/login/check')
        .then((response) => {
          store.commit('setToken', response.data.token);
        })
        .catch((error) => {
          store.commit('logout');
          router.push('/login');
        });
    }
    if (response && response.data != null && response.data.status === 403) {
      notifications.methods.showError(response.data.message);
    }
    // TODO: заготовка под вывод ошибок
    // notifications.methods.showError("") || notifications.toastr.showError("")
    // if (error.response.data.error) {
    //   error.response.data.error.exception.map((error) => {
    //     this.dangerModal.desc = this.dangerModal.desc + "\n" + error.message
    //   })
    // } else {
    //   error.response.data.map((error) => {
    //     this.dangerModal.desc = this.dangerModal.desc + "\n" + error.message
    //   })
    // }
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .get(process.env.VUE_APP_API_URL+'/api/login/check', {
          params: {
            refreshToken: localStorage.refreshToken,
          },
        })
        .then(({data}) => {
          store.commit('setToken', data.token);
          store.commit('setRefreshToken', data.refreshToken);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
          return axios(originalRequest);
        })
        .catch((error) => {
          store.commit('logout');
          router.push('/login');
        });
    }
    console.log(error.response);
    if (error.response?.status === 404) {
      notifications.methods.showError(error.response.data.message);
    } else if (error.response?.status === 403) {
      notifications.methods.showError('У Вас нет прав для совершения данного действия');
    } else if (error.response?.status === 422) {
      const title = error.response.data.title;
      if (error.response.data.errors) {
        error.response.data.errors.forEach((error) => {
          notifications.methods.showWarn(error.message, title);
        });
      }
      if (error.response.data.message) {
        notifications.methods.showWarn(error.response.data.message, title);
      }
    } else if (error.response?.data) {
      if (/MQCONN/.test(error.response.data.previous)) {
        notifications.methods.showError('В данный момент производимое действие невозможно, попробуйте позже');
      } else if (error.response?.data?.detail) {
        notifications.methods.showError(error.response.data.detail);
      } else {
        notifications.methods.showError(error.response.data.message);
      }
      return error.response.message;
    } else {
      return error.response.message;
    }
  },
);

const queryToUrl = (url, query) => {
  url = url + '?' + qs.stringify(query, {arrayFormat: 'indices'});
  return url;
};

const URLToQuery = (url) => {
  let query;
  const index = url.indexOf('?');
  if (index > -1) {
    query = url.slice(index + 1);
    return qs.parse(query, {arrayFormat: 'repeat'});
  }
  return {};
};


export {http, queryToUrl, URLToQuery};
