<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {isAuth} from './services/api';
import Auth from './services/Auth';

export default {
  name: 'App',
  created() {
    this.init();
  },
  methods: {
    init() {
      // ones
      this.unwatch = this.$watch('$route', function() {
        // this.checkAuth()
        this.unwatch();
      });
    },
    async checkAuth() {
      // if(localStorage.token) {
      //   let res = await isAuth()
      //   if (res.data.token) {
      //     let userData = Auth.parseJwt(res.data.token)
      //     // if(userData.ownerType === 'customer'){
      //     // }
      //     userData.verified = true
      //     // userData.isAuth = true
      //     this.$store.commit('setToken', res.data.token)
      //     this.$store.commit('login', userData)
      //   }
      // } else {
      //   this.$store.commit('logout')
      //   this.$router.push({
      //     path: "/login"
      //   });
      // }
    },
  },
};
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  /* Import Element IO Styles */
  @import '~element-ui/lib/theme-chalk/button.css';
  @import '~element-ui/lib/theme-chalk/upload.css';
  @import '~element-ui/lib/theme-chalk/icon.css';
  @import '~element-ui/lib/theme-chalk/select.css';
  @import '~element-ui/lib/theme-chalk/select-dropdown.css';
  @import '~element-ui/lib/theme-chalk/option.css';
  @import '~element-ui/lib/theme-chalk/option-group.css';
  @import '~element-ui/lib/theme-chalk/loading.css';
  @import '~element-ui/lib/theme-chalk/date-picker.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
