<script>
import {Select} from 'element-ui';

const ElSelectClearable = {
  name: 'ElSelectClearable',
  extends: Select,
  methods: {
    clearField() {
      this.query = '';
    },
  },
  mounted() {
    this.$el.addEventListener('change', this.clearField);
  },
};

export default ElSelectClearable;
</script>

<style>
.el-select__tags {
  padding-top: 4px;
  padding-bottom: 4px;
  scrollbar-color: lightgray;
  scrollbar-width: thin;
}

.el-select__tags::-webkit-scrollbar {
  height: 6px;
  width: 2px;
}

.el-select__tags::-webkit-scrollbar-thumb:horizontal{
  background: lightgray;
  border-radius: 10px;
}
</style>