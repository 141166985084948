<template>
  <v-client-table
    v-if="ratingIndexOptions"
    :columns="columns"
    :options="options"
    :theme="theme"
    :data="ratingIndexOptions"
  >
    <span
      slot="value"
      slot-scope="props"
    >
      от {{ props.row.min }} до {{ props.row.max }}
    </span>
    <span
      slot="rating"
      slot-scope="props"
    >
      {{ props.row.value }}
    </span>
  </v-client-table>
</template>

<script>
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import moment from 'moment';

Vue.use(ClientTable);
export default {
  name: 'RatingIndexOptionsTable',
  props: ['ratingIndexOptions', 'optionValueName'],
  data() {
    return {
      theme: 'bootstrap4',
      columns: ['value', 'rating'],
      options: {
        headings: {
          'value': this.optionValueName,
          'rating': 'Балл',

        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: false,
        texts: {
          count: '',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
    };
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
  },
};
</script>

<style scoped>

</style>
