import { http, queryToUrl } from "../http";
import axios from 'axios';

// Activation Email start
export const activationEmail = (body) => {
	const url = "/api/activation/email";
	return http.post(url, body);
};
// Activation Email end

// Activation Invitation start
export const activationInvitation = (body) => {
	const url = "/api/activation/invitation";
	return http.post(url, body);
};
// Activation Invitation end

// Attorneys start
export const contractorsAttorneysList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/attorneys";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsAttorneyCreate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/attorneys";
	return http.post(url, body);
};

export const contractorsAttorneyRead = (contractorId, attorneysId) => {
	const url =
		"/api/contractors/" + contractorId + "/attorneys/" + attorneysId;
	return http.get(url);
};

export const contractorsAttorneyUpdate = (contractorId, attorneysId, body) => {
	const url =
		"/api/contractors/" + contractorId + "/attorneys/" + attorneysId;
	return http.put(url, body);
};

export const contractorsAttorneyDelete = (contractorId, attorneysId) => {
	const url =
		"/api/contractors/" + contractorId + "/attorneys/" + attorneysId;
	return http.delete(url);
};

export const contractorsAttorneyCancel = (contractorId, attorneysId, body) => {
	const url =
		"/api/customer/contractors/" +
		contractorId +
		"/attorneys/" +
		attorneysId +
		"/cancel";
	return http.patch(url, body);
};

export const contractorsAttorneyUncancel = (contractorId, attorneysId) => {
	const url =
		"/api/customer/contractors/" +
		contractorId +
		"/attorneys/" +
		attorneysId +
		"/uncancel";
	return http.patch(url);
};

export const customersAttorneysList = (params) => {
	let url = "/api/customer/contractors/attorneys";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorsAttorneysList = (contractorId, params) => {
	let url = "/api/customer/contractors/" + contractorId + "/attorneys";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorsAttorneyRead = (contractorId, attorneysId) => {
	const url =
		"/api/customer/contractors/" +
		contractorId +
		"/attorneys/" +
		attorneysId;
	return http.get(url);
};

export const customersContractorsAttorneyUpdate = (
	contractorId,
	attorneysId,
	body
) => {
	const url =
		"/api/customer/contractors/" +
		contractorId +
		"/attorneys/" +
		attorneysId;
	return http.put(url, body);
};
// Attorneys end

// Auctions start
export const contractorsAuctionsRoutesList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/routes-in-auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsAuctionsList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsAuctionRead = (contractorId, auctionsId) => {
	const url = "/api/contractors/" + contractorId + "/auctions/" + auctionsId;
	return http.get(url);
};

export const contractorsAuctionBid = (auctionsId, body) => {
	const url = "/api/auctions/" + auctionsId + "/bids";
	return http.post(url, body);
};

export const contractorsAuctionOffer = (auctionsId, body) => {
	const url = "/api/auctions/" + auctionsId + "/offers";
	return http.post(url, body);
};

export const contractorsAuctionEditOffer = (auctionsId, offerId, body) => {
	const url = "/api/auctions/" + auctionsId + "/offers/" + offerId;
	return http.put(url, body);
};

export const contractorsAuctionAcceptWin = (contractorId, auctionsId) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/auctions/" +
		auctionsId +
		"/accept_win";
	return http.patch(url);
};

export const contractorsAuctionDeclineWin = (
	contractorId,
	auctionsId,
	type,
	comment
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/auctions/" +
		auctionsId +
		"/decline_win";
	return http.patch(url, {
		type: type,
		comment: "",
	});
};

export const customersAuctionsContractorsList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/contractors-in-auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersAuctionsRoutesList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/routes-in-auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersAuctionsList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersAuctionCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/auctions";
	return http.post(url, body);
};

export const customersAuctionRead = (customerId, auctionsId) => {
	const url = "/api/customers/" + customerId + "/auctions/" + auctionsId;
	return http.get(url);
};

export const customersAuctionUpdate = (customerId, auctionsId, body) => {
	const url = "/api/customers/" + customerId + "/auctions/" + auctionsId;
	return http.put(url, body);
};

export const customersAuctionRun = (customerId, auctionsId, body) => {
	const url =
		"/api/customers/" + customerId + "/auctions/" + auctionsId + "/run";
	return http.patch(url, body);
};

export const customersAuctionClearRun = (customerId, auctionsId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/auctions/" +
		auctionsId +
		"/clear_run";
	return http.patch(url, body);
};

export const customersAuctionChangeDates = (customerId, auctionsId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/auctions/" +
		auctionsId +
		"/change_dates";
	return http.patch(url, body);
};

export const customersAuctionDraft = (customerId, auctionsId) => {
	const url =
		"/api/customers/" + customerId + "/auctions/" + auctionsId + "/draft";
	return http.patch(url);
};

export const customersAuctionCancel = (customerId, auctionsId) => {
	const url =
		"/api/customers/" + customerId + "/auctions/" + auctionsId + "/cancel";
	return http.patch(url);
};

export const customersAuctionSetWinner = (customerId, auctionsId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/auctions/" +
		auctionsId +
		"/set_winner";
	return http.patch(url, body);
};
// Auctions end

// Cargo Pipeline start
export const cargoPipelinePlacesEvent = (params) => {
	let url = "/api/cargo-pipeline-places-event";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const cargoPipelineEvent = (params) => {
	let url = "/api/cargo-pipeline-event";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Cargo Pipeline end

// Contact Requisites start
export const contactRequisitesList = (params) => {
	let url = "/api/contact-requisites";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contactRequisitesCreate = (body) => {
	const url = "/api/contact-requisites";
	return http.post(url, body);
};

export const contactRequisitesRead = (contactRequisiteId) => {
	const url = "/api/contact-requisites/" + contactRequisiteId;
	return http.get(url);
};

export const contactRequisitesUpdate = (contactRequisiteId, body) => {
	const url = "/api/contact-requisites/" + contactRequisiteId;
	return http.put(url, body);
};

export const contactRequisitesDelete = (contactRequisiteId) => {
	const url = "/api/contact-requisites/" + contactRequisiteId;
	return http.delete(url);
};

export const contractorsContactRequisitesList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/contact-requisites";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsContactRequisitesPatch = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/contact-requisites";
	return http.patch(url, body);
};
// Contact Requisites end

// Containers start
export const containerRead = (containerId) => {
	const url = "/api/containers/" + containerId;
	return http.get(url);
};

export const contractorsContainersList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/containers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsContainerCreate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/containers";
	return http.post(url, body);
};

export const contractorsContainerRead = (contractorId, containerId) => {
	const url =
		"/api/contractors/" + contractorId + "/containers/" + containerId;
	return http.get(url);
};

export const contractorsContainerDelete = (contractorId, containerId) => {
	const url =
		"/api/contractors/" + contractorId + "/containers/" + containerId;
	return http.delete(url);
};

export const checkContractorsContainerWithNumber = (contractorId, number) => {
	let url =
		"/api/contractors/" +
		contractorId +
		"/containers/find_container_with_number";
	url = queryToUrl(url, {
		number: number,
	});
	return http.get(url);
};
// Containers end

// Containers Models start
export const containersTypesList = (params) => {
	let url = "/api/container-types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const containerTypeRead = (containerTypeId) => {
	const url = "/api/container-type/" + containerTypeId;
	return http.get(url);
};
// Containers Models end

// Contractors start
export const contractorCreate = (body) => {
	const url = "/api/register/contractor";
	return http.post(url, body);
};

export const contractorRead = (contractorId) => {
	const url = "/api/contractors/" + contractorId;
	return http.get(url);
};

export const contractorUpdate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId;
	return http.put(url, body);
};

export const contractorToVerify = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/status/2";
	return http.patch(url, body);
};

export const contractorsRelatedOwnersList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/related_owners";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Contractors end

// Contractors Partners start
export const contractorsPartnerList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/partners";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsPartnersDriversList = (
	contractorId,
	partnerId,
	params
) => {
	let url =
		"/api/contractors/" +
		contractorId +
		"/partners/" +
		partnerId +
		"/drivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsPartnersTransportVehiclesList = (
	contractorId,
	partnerId,
	params
) => {
	let url =
		"/api/contractors/" +
		contractorId +
		"/partners/" +
		partnerId +
		"/vehicles";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

// Contractors Partners end

// Contractors Users start
export const contractorsUsersList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/users";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsUserCreate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/users";
	return http.post(url, body);
};

export const contractorsUserUpdate = (customerId, userId, body) => {
	const url = "/api/contractors/" + customerId + "/users/" + userId;
	return http.put(url, body);
};
export const contractorsUserRead = (customerId, userId) => {
	const url = "/api/contractors/" + customerId + "/users/" + userId;
	return http.get(url);
};
export const contractorsUserBlock = (customerId, userId, body) => {
	const url =
		"/api/contractors/" + customerId + "/users/" + userId + "/block";
	return http.patch(url, body);
};
export const contractorsUserUnblock = (customerId, userId, body) => {
	const url =
		"/api/contractors/" + customerId + "/users/" + userId + "/unblock";
	return http.patch(url, body);
};
// Contractors Users end

// Contractors Validation Documents start
export const contractorsValidationDocuments = (body) => {
	const url = "/api/contractor-validatation-documents";
	return http.post(url, body);
};
// Contractors Validation Documents end

// Customers AdUsers start
export const customersAdUsersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/adusers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersAdUserCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/users";
	return http.post(url, body);
};

export const customersAdUserRead = (customerId, userId) => {
	const url = "/api/customers/" + customerId + "/users/" + userId;
	return http.get(url);
};

export const customersAdUserUpdate = (customerId, userId, body) => {
	const url = "/api/customers/" + customerId + "/users/" + userId;
	return http.put(url, body);
};

export const customersUserBlock = (customerId, userId, body) => {
	const url = "/api/customers/" + customerId + "/users/" + userId + "/block";
	return http.patch(url, body);
};
export const customersUserUnblock = (customerId, userId, body) => {
	const url =
		"/api/customers/" + customerId + "/users/" + userId + "/unblock";
	return http.patch(url, body);
};
// Customers AdUsers end

// Customers Contractors start
export const customersContractorsList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/contractors";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersAllContractorsList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/all-contractors";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorRead = (customerId, contractorId) => {
	const url = "/api/customers/" + customerId + "/contractors/" + contractorId;
	return http.get(url);
};

export const customersContractorVerify = (customerId, contractorId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/status/1";
	return http.patch(url, body, {
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	});
};

export const customersContractorReject = (customerId, contractorId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/status/3";
	return http.patch(url, body, {
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	});
};
// Customers Contractors end

// Customers Contractors Lists start
export const customersContractorsListsList = (customerId, params) => {
	let url = "/api/customer/" + customerId + "/contractors-lists";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorsListCreate = (customerId, body) => {
	const url = "/api/customer/" + customerId + "/contractors-lists";
	return http.post(url, body);
};

export const customersContractorsListRead = (
	customerId,
	contractorsListsId
) => {
	const url =
		"/api/customer/" +
		customerId +
		"/contractors-lists/" +
		contractorsListsId;
	return http.get(url);
};

export const customersContractorsListUpdate = (
	customerId,
	contractorsListsId,
	body
) => {
	const url =
		"/api/customer/" +
		customerId +
		"/contractors-lists/" +
		contractorsListsId;
	return http.put(url, body);
};

export const customersContractorsListDelete = (
	customerId,
	contractorsListsId
) => {
	const url =
		"/api/customer/" +
		customerId +
		"/contractors-lists/" +
		contractorsListsId;
	return http.delete(url);
};
// Customers Contractors Lists end

// Customers Partners start
export const customersContractorsPartnersCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/contractor_partners";
	return http.post(url, body);
};

export const customersContractorsPartnersDelete = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/contractor_partners";
	return http.delete(url, { data: body });
};
// Customers Partners end

// Customers Users start
export const customersUsersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/users";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersUserRead = (customerId, userId) => {
	const url = "/api/customers/" + customerId + "/users/" + userId;
	return http.get(url);
};

export const customersUserReadWithContractors = (customerId, userId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/users/" +
		userId +
		"/with-contractors";
	return http.get(url);
};

export const customersUserReadWithRouteOwners = (customerId, userId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/users/" +
		userId +
		"/with-route-owners";
	return http.get(url);
};
// Customers Users end

// Documents/Files start
export const documentCreate = (body) => {
	const url = "/api/documents/upload";
	return http.post(url, body);
};

export const documentDownload = (documentUrl) => {
	return http.get(documentUrl, { responseType: "blob" });
};

export const fileDownload = (fileId) => {
	const url = "/api/files/" + fileId + "/download";
	return http.get(url, { responseType: "blob" });
};
// Documents/Files end

// Dimensions start
export const transportVehiclesDimensionTypeCreate = (customerId, body) => {
	const url = `/api/customers/${customerId}/vehicles/dimensions`;
	return http.post(url, body);
};

export const transportVehiclesDimensionTypeRead = (dimensionId) => {
	const url = `/api/vehicles/dimensions/${dimensionId}`;
	return http.get(url);
};

export const transportVehiclesDimensionTypeUpdate = (
	customerId,
	dimensionId,
	body
) => {
	const url = `/api/customers/${customerId}/vehicles/dimensions/${dimensionId}`;
	return http.put(url, body);
};

export const routeWayDimensions = (customerId, params) => {
	let url = `/api/customers/${customerId}/route-way-dimensions`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url, params);
};

export const routeWayDimensionView = (customer_id, route_way_dimension_id) => {
	const url =
		"/api/customers/" +
		customer_id +
		"/route-way-dimension-bunch/" +
		route_way_dimension_id;
	return http.get(url);
};

export const routeWayDimensionUpdate = (
	customer_id,
	route_way_dimension_id,
	body
) => {
	const url =
		"/api/customers/" +
		customer_id +
		"/route-way-dimension/" +
		route_way_dimension_id;
	return http.put(url, body);
};

export const routeWayDimensionDelete = (customerId, dimensionId) => {
	const url =
		"/api/customers/" + customerId + "/route-way-dimension/" + dimensionId;
	return http.delete(url);
};

export const routeWayDimensionCreate = (customerId, body) => {
	const url = `/api/customers/${customerId}/route-way-dimension`;
	return http.post(url, body);
};

export const routeWayDimensionForRouteWay = (customerId, routeWayId) => {
	const url =
		"/api/customers/" + customerId + "/route-way-dimension/" + routeWayId;
	return http.get(url);
};
// Dimensions end

// Dimension Calculate start
export const dimensionCalculates = (customerId, params) => {
	let url = `/api/customers/${customerId}/dimension/calculate`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const dimensionCalculateRead = (customerId, dimensionId) => {
	const url = `/api/customers/${customerId}/dimension/${dimensionId}/calculate`;
	return http.get(url);
};

export const dimensionCalculateUpdate = (customerId, dimensionId, body) => {
	const url = `/api/customers/${customerId}/dimension/${dimensionId}/calculate`;
	return http.put(url, body);
};
// Dimension Calculate end

// Drivers start
export const contractorsAllDriverList = (contractorId, params) => {
	let url = "/api/contractor/" + contractorId + "/alldrivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsDriverList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/drivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsDriverCreate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/drivers";
	return http.post(url, body);
};

export const contractorsDriverRead = (contractorId, driverId) => {
	const url = "/api/contractor/" + contractorId + "/drivers/" + driverId;
	return http.get(url);
};

export const contractorsDriverUpdate = (contractorId, driverId, body) => {
	const url = "/api/contractors/" + contractorId + "/drivers/" + driverId;
	return http.put(url, body);
};

export const contractorsDriverDelete = (contractorId, contractorDriverId) => {
	const url =
		"/api/contractors/" + contractorId + "/drivers/" + contractorDriverId;
	return http.delete(url);
};

export const contractorsDriverToCheck = (contractorId, contractorDriverId) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/drivers/" +
		contractorDriverId +
		"/tocheck";
	return http.patch(url);
};

export const contractorsDriverUncancel = (contractorId, driverId) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/drivers/" +
		driverId +
		"/uncancel";
	return http.patch(url);
};

export const checkDriverWithPassportData = (
	contractorId,
	passportSeries,
	passportNumber,
	passportIssueDate
) => {
	let url =
		"/api/contractors/" +
		contractorId +
		"/drivers/find_driver_with_passport";
	url = queryToUrl(url, {
		passport_series: passportSeries,
		passport_number: passportNumber,
		passport_issue_date: passportIssueDate,
	});
	return http.get(url);
};

export const checkContractorsDriverByDriverId = (contractorId, driverId) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/drivers/" +
		driverId +
		"/find_by_keys";
	return http.get(url);
};

export const customersDriverList = (params) => {
	let url = "/api/customer/drivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDriverSimpleList = (params) => {
	let url = "/api/customer/drivers_simple";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDriverRead = (driverId) => {
	const url = "/api/customer/drivers/" + driverId;
	return http.get(url);
};

export const customersContractorsDriverToCancel = (
	contractorDriverId,
	comment
) => {
	const url = "/api/customer/drivers/" + contractorDriverId + "/tocancel";
	return http.patch(url, { customer_comment: comment });
};

export const customersContractorsDriverToNew = (
	contractorDriverId,
	comment
) => {
	const url = "/api/customer/drivers/" + contractorDriverId + "/tonew";
	return http.patch(url, { customer_comment: comment });
};

export const customersContractorsDriverToVerification = (
	contractorDriverId
) => {
	const url =
		"/api/customer/drivers/" + contractorDriverId + "/to_verification";
	return http.patch(url);
};

export const customersContractorsDriversList = (contractorId, params) => {
	let url = "/api/customer/contractors/" + contractorId + "/drivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorDriversList = (contractorId, params) => {
	let url = "/api/customer/contractors/" + contractorId + "/drivers";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDriverDraftsContractorsList = (params) => {
	let url = "/api/customer/contractors_in_drafts";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDriverDrafts = (params) => {
	let url = "/api/customer/driver_drafts";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDriverDraftsRead = (driverDraftId) => {
	const url = "/api/customer/driver_drafts/" + driverDraftId;
	return http.get(url);
};

export const customersDriverDraftsAccept = (driverDraftId) => {
	const url = "/api/customer/driver_drafts/" + driverDraftId + "/accept";
	return http.patch(url);
};

export const customersDriverDraftsDecline = (driverDraftId) => {
	const url = "/api/customer/driver_drafts/" + driverDraftId + "/decline";
	return http.patch(url);
};
// Drivers end

// Drivers Passport Types start
export const passportTypesList = (params) => {
	let url = "/api/drivers/passport_types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Drivers Passport Types end

// Notifications start
export const notificationsRead = (userId, params) => {
	let url = "/api/users/" + userId + "/notifications";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const notificationsReadNotification = (
	userId,
	notificationId,
	params
) => {
	let url = "/api/users/" + userId + "/notifications/" + notificationId;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const notificationsReadNotifications = (userId, body) => {
	const url = "/api/users/" + userId + "/notifications/mark_as_read";
	return http.patch(url, body);
};

export const notificationsReadAllNotifications = (userId, body) => {
	const url = "/api/users/" + userId + "/notifications/mark_all_as_read";
	return http.patch(url, body);
};
// Notifications end

// Phone Country Codes start
export const phoneCountryCodesList = (params) => {
	let url = "/api/phone-country-codes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Phone Country Codes end

// Routes start
export const contractorsRoutesTransportVehiclesList = (
	contractorId,
	params
) => {
	let url = "/api/contractors/" + contractorId + "/vehicles-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsRoutesDriversList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/drivers-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsRoutesLegitDriversList = (contractorId, params) => {
	let url = `/api/contractors/${contractorId}/legit-drivers`;
	if (params) url = queryToUrl(url, params);
	return http.get(url);
};

export const contractorsRoutesRouteWaysList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/route-ways-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsRoutesList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url, params);
};

export const contractorsRouteRead = (contractorId, routeId) => {
	const url = "/api/contractors/" + contractorId + "/routes/" + routeId;
	return http.get(url);
};

export const contractorsRouteUpdate = (contractorId, routeId, body) => {
	const url = "/api/contractors/" + contractorId + "/routes/" + routeId;
	return http.put(url, body);
};

export const contractorsRouteSetReady = (customerId, routeId) => {
	const url =
		"/api/contractors/" + customerId + "/routes/" + routeId + "/set_ready";
	return http.patch(url);
};

export const contractorsRouteCreateDisclaimer = (
	customerId,
	routeId,
	comment
) => {
	const url =
		"/api/contractors/" +
		customerId +
		"/routes/" +
		routeId +
		"/create_disclaimer";
	return http.post(url, { comment: comment });
};

export const customersRoutesTransportVehiclesList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/vehicles-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesDriversList = (params) => {
	let url = "/api/customer/drivers-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesRouteWaysList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/route-ways-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesContractorsList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/contractors-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesManagersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/managers-in-routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/routes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url, params);
};

export const customersRouteCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/routes";
	return http.post(url, body);
};

export const customersRouteAuctionCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/routes-auction/";
	return http.post(url, body);
};

export const customersRouteRead = (customerId, routeId) => {
	const url = "/api/customers/" + customerId + "/routes/" + routeId;
	return http.get(url);
};

export const customersRouteTariffTenderRead = (routeWayId) => {
	const url = "/api/route-tariff/tender/route-way/" + routeWayId;
	return http.get(url);
};

export const customersRouteUpdate = (customerId, routeId, body) => {
	const url = "/api/customers/" + customerId + "/routes/" + routeId;
	return http.put(url, body);
};

export const customersRouteDelete = (customerId, routeId) => {
	const url = "/api/customers/" + customerId + "/routes/" + routeId;
	return http.delete(url);
};

export const customersRouteUncancel = (customerId, routeId) => {
	const url =
		"/api/customers/" + customerId + "/routes/" + routeId + "/uncancel";
	return http.patch(url);
};

export const customersRouteAcceptDisclaimer = (customerId, routeId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/routes/" +
		routeId +
		"/accept_disclaimer";
	return http.patch(url);
};

export const customersRouteDeclineDisclaimer = (customerId, routeId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/routes/" +
		routeId +
		"/decline_disclaimer";
	return http.patch(url);
};

export const customersRouteSetDraft = (customerId, routeId) => {
	const url =
		"/api/customers/" + customerId + "/routes/" + routeId + "/set_draft";
	return http.patch(url);
};

export const customersRoutesForAuctions = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/routes_for_auctions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url, params);
};
// Routes end

// Route Ways start
export const routeWaysList = (params) => {
	let url = "/api/route_ways";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const routeWaysShortlist = (params) => {
	let url = "/api/route_ways_short";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const routeWayRead = (routeWayId) => {
	const url = "/api/route_ways/" + routeWayId;
	return http.get(url);
};
// Route Ways end

// Statistics start
export const customersManagerAuctionStatistics = (customerId, params) => {
	let url = "/api/statistics/" + customerId + "/manager_auction_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersDateAuctionStatistics = (customerId, params) => {
	let url = "/api/statistics/" + customerId + "/date_auction_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRoutesInAuctionsStatistics = (customerId, params) => {
	let url = "/api/statistics/" + customerId + "/routes-in-auctions-statistic";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorsInAuctionsStatistics = (
	customerId,
	params
) => {
	let url =
		"/api/statistics/" + customerId + "/contractors-in-auctions-statistic";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRouteStatistics = (customerId, params) => {
	let url = "/api/statistics/" + customerId + "/route_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersRouteTotalDistributionStatistics = (
	customerId,
	params
) => {
	let url =
		"/api/statistics/" +
		customerId +
		"/route_total_distribution_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const routesNumberDistributionStatistic = (customerId, params) => {
	let url =
		"/api/statistics/" +
		customerId +
		"/route_number_distribution_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const routesTariffDifferenceStatistic = (
	customerId,
	params,
	cancelToken
) => {
	let url = "/api/statistics/" + customerId + "/route_tariff_difference";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url, { cancelToken: cancelToken });
};

export const routesMoneyDistributionStatistic = (customerId, params) => {
	let url =
		"/api/statistics/" +
		customerId +
		"/route_total_distribution_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const tenderEconomyStatistic = (customerId, params) => {
	let url = "/api/statistics/" + customerId + "/tender_economy_statistics";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Statistics end

let cancelToken;

// Tenders start
export const contractorsTendersRouteWaysList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/route-ways-in-tenders";
	if (cancelToken) {
	  cancelToken.cancel("Previous request cancelled");
	}
	cancelToken = axios.CancelToken.source();
	if (params) {
	  url = queryToUrl(url, params);
	}
	let result = axios.get(process.env.VUE_APP_API_URL + url,
	  {
		cancelToken: cancelToken.token,
		headers: {'Authorization': 'bearer ' + localStorage.token}
	  }
	);
	return result;
};

export const contractorsTendersList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/tenders";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsTenderRead = (contractorId, tendersId) => {
	const url = "/api/contractors/" + contractorId + "/tenders/" + tendersId;
	return http.get(url);
};

export const contractorsTendersRouteTemplateBids = (
	contractorId,
	tendersId,
	routeTemplateId,
	body
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/route_template/" +
		routeTemplateId +
		"/bids";
	return http.post(url, body);
};

export const contractorsTendersRouteTemplateOffer = (
	contractorId,
	tendersId,
	routeTemplateId,
	body
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/route_template/" +
		routeTemplateId +
		"/offers";
	return http.post(url, body);
};

export const contractorsTendersRouteTemplateEditOffer = (
	contractorId,
	tendersId,
	routeTemplateId,
	offerId,
	offerSum
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/route_template/" +
		routeTemplateId +
		"/offers/" +
		offerId;
	return http.patch(url, { summ: offerSum });
};

export const contractorsTendersRouteTemplateDeleteOffer = (
	contractorId,
	tendersId,
	routeTemplateId,
	body
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/route_template/" +
		routeTemplateId +
		"/offers";
	return http.delete(url, body);
};

export const contractorsTenderAcceptWin = (contractorId, tendersId) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/acceptwin";
	return http.patch(url);
};

export const contractorsTenderDeclineWin = (
	contractorId,
	tendersId,
	comment
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/tenders/" +
		tendersId +
		"/declinewin";
	return http.patch(url, { comment: "" });
};

export const customersTendersWinnersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/winners-in-tenders";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersTendersRouteWaysList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/route-ways-in-tenders";
	if (cancelToken) {
	  cancelToken.cancel("Previous request cancelled");
	}
	cancelToken = axios.CancelToken.source();
	if (params) {
	  url = queryToUrl(url, params);
	}
	let result = axios.get(process.env.VUE_APP_API_URL + url,
	  {
		cancelToken: cancelToken.token,
		headers: {'Authorization': 'bearer ' + localStorage.token}
	  }
	);
	return result;
  };

export const customersTendersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/tenders";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersTenderCreate = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/tenders";
	return http.post(url, body);
};

export const customersTenderCreateAndRun = (customerId, body) => {
	const url = "/api/customers/" + customerId + "/tenders/create_and_run";
	return http.post(url, body);
};

export const customersTenderRead = (customerId, tendersId) => {
	const url = "/api/customers/" + customerId + "/tenders/" + tendersId;
	return http.get(url);
};

export const customersTenderUpdate = (customerId, tendersId, body) => {
	const url = "/api/customers/" + customerId + "/tenders/" + tendersId;
	return http.put(url, body);
};

export const customersTenderRun = (customerId, tendersId, body) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/run";
	return http.patch(url, body);
};

export const customersTenderClearRun = (customerId, tendersId, body) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/clear_run";
	return http.patch(url, body, {
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	});
};

export const customersTenderChangeDates = (customerId, tendersId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/tenders/" +
		tendersId +
		"/change_dates";
	return http.patch(url, body);
};

export const customersTenderRerun = (customerId, tendersId, body) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/rerun";
	return http.patch(url, body);
};

export const customersTenderDraft = (customerId, tendersId) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/todraft";
	return http.patch(url);
};

export const customersTenderCancel = (customerId, tendersId) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/cancel";
	return http.patch(url);
};

export const customersTenderEnd = (customerId, tendersId) => {
	const url =
		"/api/customers/" + customerId + "/tenders/" + tendersId + "/end";
	return http.patch(url);
};

export const customersTenderAcceptWinner = (customerId, tendersId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/tenders/" +
		tendersId +
		"/acceptwinner";
	return http.patch(url);
};

export const customersTenderSetWinner = (customerId, tendersId, winnerId) => {
	const url =
		"/api/customers/" +
		customerId +
		"/tenders/" +
		tendersId +
		"/setwinner/" +
		winnerId;
	return http.patch(url);
};
// Tenders end

// Transportation Types Routes start
export const routesTransportationTypesList = (params) => {
	let url = "/api/transportations";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const routesTransportationTypeRead = (transportationId) => {
	const url = `/api/transportations/${transportationId}`;
	return http.get(url);
};

export const routesTransportationTypeSettingsUpdate = (
	transportationId,
	body
) => {
	const url = `/api/transportations/${transportationId}/settings`;
	return http.put(url, body);
};
// Transportation Types Routes end

// Transport Vehicles start
export const contractorsTransportVehiclesList = (contractorId, params) => {
	let url = "/api/contractors/" + contractorId + "/vehicles";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerContractorsTransportVehiclesList = (
	customerId,
	params
) => {
	let url = "/api/customers/" + customerId + "/contractor-vehicles";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorsTransportVehicleCreate = (contractorId, body) => {
	const url = "/api/contractors/" + contractorId + "/vehicles";
	return http.post(url, body);
};

export const contractorsTransportVehicleRead = (
	contractorId,
	transportVehicleId
) => {
	const url =
		"/api/contractors/" + contractorId + "/vehicles/" + transportVehicleId;
	return http.get(url);
};

export const contractorsTransportVehicleUpdate = (
	contractorId,
	transportVehicleId,
	body
) => {
	const url =
		"/api/contractors/" + contractorId + "/vehicles/" + transportVehicleId;
	return http.put(url, body);
};

export const contractorsTransportVehicleDelete = (
	contractorId,
	transportVehicleId
) => {
	const url =
		"/api/contractors/" + contractorId + "/vehicles/" + transportVehicleId;
	return http.delete(url);
};

export const contractorsTransportVehicleSendToCheck = (
	contractorId,
	contractorTransportVehicleId
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/vehicles/" +
		contractorTransportVehicleId +
		"/send_to_check";
	return http.patch(url);
};

export const checkContractorsVehicleWithStsAndRegistrationNumber = (
	contractorId,
	sts,
	registrationNumber
) => {
	let url =
		"/api/contractors/" +
		contractorId +
		"/vehicles/find_vehicle_with_numbers";
	url = queryToUrl(url, {
		sts_number: sts,
		registration_number: registrationNumber,
	});
	return http.get(url);
};

export const departmentsList = (params) => {
	let url = "/api/departments";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersContractorsTransportVehicleToCancel = (
	contractorId,
	contractorTransportVehicleId,
	comment
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/vehicles/" +
		contractorTransportVehicleId +
		"/to_cancel";
	return http.patch(url, { customer_comment: comment });
};

export const customersContractorsTransportVehicleToNew = (
	contractorId,
	contractorTransportVehicleId,
	comment
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/vehicles/" +
		contractorTransportVehicleId +
		"/to_new";
	return http.patch(url, { customer_comment: comment });
};

export const customersContractorsTransportVehicleToVerification = (
	contractorId,
	contractorTransportVehicleId
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/vehicles/" +
		contractorTransportVehicleId +
		"/send_to_verification";
	return http.patch(url);
};

export const customersTransportVehicleList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/vehicles";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customersTransportVehicleRead = (transportVehicleId) => {
	const url = "/api/vehicles/" + transportVehicleId;
	return http.get(url);
};

export const transportVehiclesAuctionBodyTypes = (params) => {
	let url = "/api/vehicles/auction_body_types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const transportVehiclesLoadingTypes = (params) => {
	let url = "/api/vehicles/loading_types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const transportVehiclesDimensionsTypes = (params) => {
	let url = "/api/vehicles/dimensions";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const transportVehiclesDimensionsTypesRead = (dimensionsTypeId) => {
	const url = "/api/vehicles/dimensions/" + dimensionsTypeId;
	return http.get(url);
};
// Transport Vehicles end

// Transport Vehicles Models start
export const vehicleModelsList = (params) => {
	let url = "/api/vehicles/models";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const vehiclesModelRead = (modelId, params) => {
	const url = "/api/vehicles/models/" + modelId;
	return http.get(url, params);
};

export const vehicleModelsTypesList = (params) => {
	let url = "/api/vehicles/types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const vehicleModelsBodyTypesList = (params) => {
	let url = "/api/vehicles/body_types";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Transport Vehicles Models end

// User start
export const signIn = (body) => {
	const url = "/api/login";
	return http.post(url, body);
};

export const isAuth = (body) => {
	const url = "/api/login/check11";
	return http.get(url, body);
};

export const userRead = (userId) => {
	const url = "/api/users/" + userId;
	return http.get(url);
};

export const userUpdate = (userId, body) => {
	const url = "/api/users/" + userId;
	return http.put(url, body);
};

export const userDelete = (userId) => {
	const url = "/api/users/" + userId;
	return http.delete(url);
};
// User end

// Validation Documents start
export const validationDocumentsList = (params) => {
	let url = "/api/validation-documents";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
// Verification end

// Verification start
export const checkContractorVerification = (id) => {
	const url = "/api/contractors/" + id;
	return http.get(url);
};
// Verification end

// Заброс на сброс пароля
export const forgotPassword = (body) => {
	const url = "/api/drop-password-message";
	return http.post(url, body);
};

// Cброс пароля
export const dropPassword = (body) => {
	const url = "/api/drop-set-password";
	return http.post(url, body);
};

// Verification start
export const getFile = (guid) => {
	const url = "/api/files/" + guid;
	return http.get(url);
};

export const downloadFile = (file) => {
	http.get("/api/files/" + file.guid + "/download", { responseType: "blob" })
		.then(({ data }) => {
			const blob = new Blob([data], { type: file.mimeType });
			const link = document.createElement("a");
			const url = window.URL.createObjectURL(blob);
			link.href = url;
			link.download = file.originalName;
			link.target = "_blank";
			document.body.appendChild(link);
			link.click();
			setTimeout(() => {
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}, 1000);
		})
		.catch((error) => console.error(error));
};

export const viewFile = (file) => {
	http.get("/api/files/" + file.guid + "/download", { responseType: "blob" })
		.then(({ data }) => {
			const blob = new Blob([data], { type: file.mimeType });
			const url = window.URL.createObjectURL(blob);
			window.location.assign(url);
		})
		.catch((error) => console.error(error));
};

// Оповещения пользователя подрядчика
export const readContractorUserNotificationsSettingsOptions = () => {
	const url = "/api/settings/contractor-user/notifications";
	return http.get(url);
};
export const readContractorUserNotificationsSettings = (userId) => {
	const url = "/api/settings/contractor-user/" + userId + "/notifications";
	return http.get(url);
};

export const setContractorUserNotificationsSettings = (userId, body) => {
	const url = "/api/settings/contractor-user/" + userId + "/notifications";
	return http.patch(url, body);
};

// Оповещения пользователя заказчика
export const readCustomerUserNotificationsSettingsOptions = () => {
	const url = "/api/settings/customer-user/notifications";
	return http.get(url);
};
export const readCustomerUserNotificationsSettings = (userId) => {
	const url = "/api/settings/customer-user/" + userId + "/notifications";
	return http.get(url);
};

export const setCustomerUserNotificationsSettings = (userId, body) => {
	const url = "/api/settings/customer-user/" + userId + "/notifications";
	return http.patch(url, body);
};

export const customerUserContractorsUpdate = (customerUserId, body) => {
	const url = "/api/customer-users/" + customerUserId + "/contractors";
	return http.patch(url, body);
};

export const customerUserRouteOwnersUpdate = (customerUserId, body) => {
	const url = "/api/customer-users/" + customerUserId + "/route-owners";
	return http.patch(url, body);
};

// CustomerRoutes
export const routeOwnersList = (params) => {
	let url = "/api/route-owners";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerRouteOwnersList = (customerId, params) => {
	let url = "/api/customers/" + customerId + "/routeowners";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const getCustomerRouteOwner = (customerId, routeOnwerId, params) => {
	let url = `/api/customers/${customerId}/routeowners/${routeOnwerId}`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const patchCustomerRouteOwner = (customerId, routeOnwerId, params) => {
	const url = `/api/customers/${customerId}/routeowners/${routeOnwerId}/edit_agent`;
	return http.patch(url, params);
};

// Рейтинг
export const contractorRatingList = (contractorId, params = {}, periodId) => {
	let url = "/api/contractors/" + contractorId + "/rating_list/" + periodId;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
export const customerRatingTop = (customerId, params = {}) => {
	let url = "/api/customers/" + customerId + "/rating-top";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const ratingPeriods = (params = {}) => {
	const url = "api/ratingperiods";
	return http.get(url);
};

export const contractorRatingDetail = (contractorId, params = {}) => {
	const url = "/api/contractors/" + contractorId + "/rating_details";
	return http.get(url, params);
};
export const contractorRatingDetailById = (
	contractorId,
	ratingId,
	params = {}
) => {
	const url = "/api/contractors/" + contractorId + "/ratings/" + ratingId;
	return http.get(url, params);
};
export const contractorIndexValueAppeal = (
	contractorId,
	event_id,
	params = {}
) => {
	const url =
		"/api/contractors/" +
		contractorId +
		"/events_for_appeals/" +
		event_id +
		"/appeals";
	return http.post(url, params);
};
export const contractorEventsForAppeals = (
	contractor_id,
	value_id,
	params = {}
) => {
	const url =
		"/api/contractors/" +
		contractor_id +
		"/indexvalue/" +
		value_id +
		"/events_for_appeals";
	return http.get(url, params);
};
export const contractorAppealsList = (contractor_id, params = {}) => {
	const url = `/api/contractors/${contractor_id}/appeals`;
	return http.get(url, params);
};

export const customerAppealsList = (customerId, params = {}) => {
	let url = `/api/customers/${customerId}/appeals`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerAcceptAppeal = (customerID, valueId, params = {}) => {
	const url = `/api/customers/${customerID}/appeals/${valueId}/accept`;
	return http.patch(url, params);
};

export const customerDeclineAppeal = (customerID, valueId, params = {}) => {
	const url = `/api/customers/${customerID}/appeals/${valueId}/decline`;
	return http.patch(url, params);
};

export const customerAppealToProcessing = (
	customerID,
	valueId,
	params = {}
) => {
	const url = `/api/customers/${customerID}/appeals/${valueId}/toprocessing`;
	return http.patch(url, params);
};

export const customerContractorRatingList = (
	customerId,
	contractorId,
	params = {},
	periodId
) => {
	let url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/rating_list/" +
		periodId;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};
export const customerContractorRatingDetail = (
	customerId,
	contractorId,
	params = {}
) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/rating_details";
	return http.get(url, params);
};
export const customerContractorRatingDetailById = (
	customerId,
	contractorId,
	ratingId,
	params = {}
) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/ratings/" +
		ratingId;
	return http.get(url, params);
};

export const customerContractorRatingAddBonusById = (
	customerId,
	contractorId,
	indexId,
	params = {}
) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/indexvalues/" +
		indexId;
	return http.put(url, params);
};

export const customerRatingIndexes = (customerId, params = {}) => {
	let url = "/api/customers/" + customerId + "/ratingindexes";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerRatingHistory = (customerId, params = {}) => {
	const url = "/api/customers/" + customerId + "/rating_history";
	return http.get(url, params);
};

export const updateCustomerRatingIndex = (
	customerId,
	periodIndexId,
	params = {}
) => {
	const url =
		"/api/customers/" + customerId + "/ratingindexes/" + periodIndexId;
	return http.put(url, params);
};

export const updateCustomerRatingIndexOption = (
	customerId,
	periodIndexId,
	optionId,
	params = {}
) => {
	const url =
		"/api/customers/" +
		customerId +
		"/ratingindexes/" +
		periodIndexId +
		"/options/" +
		optionId;
	return http.put(url, params);
};

export const auctionStatistics = (customerId, params = {}) => {
	let url = `/api/statistics/${customerId}/auction_statisctics`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorVideoLessonsList = (params = {}) => {
	let url = `/api/video-lessons`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const contractorVideoLesson = (lessonId, params = {}) => {
	const url = "/api/contractors/video-lessons/" + lessonId;
	return http.get(url, params);
};

export const customerVideoLessonsList = (params = {}) => {
	let url = `/api/customers/video-lessons`;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerVideoLesson = (lessonId, params = {}) => {
	const url = "/api/customers/video-lessons/" + lessonId;
	return http.get(url, params);
};

export const customerCreateVideoLesson = (params = {}) => {
	const url = "/api/customers/video-lessons";
	return http.post(url, params);
};

export const customerEditVideoLesson = (lessonId, params = {}) => {
	const url = "/api/customers/video-lessons/" + lessonId;
	return http.put(url, params);
};

export const customerPublishVideoLesson = (lessonId, params = {}) => {
	let url = "/api/customers/video-lessons/" + lessonId + "/publish";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.patch(url, params);
};

export const customerDeleteVideoLesson = (lessonId, params = {}) => {
	let url = "/api/customers/video-lessons/" + lessonId;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.delete(url, params);
};

export const customerVideoLessonSetSortPosition = (lessonId, params = {}) => {
	let url = "/api/customers/video-lessons/" + lessonId + "/set_position";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerGetAuctionPlans = (params = {}) => {
	let url = "/api/customers/auction-plan";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const customerCreateAuctionPlan = (params = {}) => {
	const url = "/api/customers/auction-plan";
	return http.post(url, params);
};

export const customerUpdateAuctionPlan = (planId, params = {}) => {
	const url = "/api/customers/auction-plan/" + planId;
	return http.put(url, params);
};

export const driverCheckLocations = (params = {}) => {
	let url = "/api/driver_check_locations";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const getExampleDocuments = (params = {}) => {
	let url = "/api/example_documents";
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const updateExampleDocument = (code, params = {}) => {
	const url = "/api/customer/example_documents/" + code;
	return http.put(url, params);
};

export const getExampleDocument = (code, params = {}) => {
	let url = "/api/example_documents/" + code;
	if (params) {
		url = queryToUrl(url, params);
	}
	return http.get(url);
};

export const changeExpressDriverCheck = (customerId, contractorId, body) => {
	const url =
		"/api/customers/" +
		customerId +
		"/contractors/" +
		contractorId +
		"/express-drivers-check";
	return http.patch(url, body, {
		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	});
};

export const organizationsList = (params = {}) => {
	const url = "/api/organizations/";
	return http.get(url, params);
};

export const contractorDriverAddInn = (contractorId, driverId, inn) => {
	const url = `/api/contractors/${contractorId}/drivers/${driverId}/add_inn?inn=${inn}`;
	return http.patch(url);
};

//User clicks
export const enterToDashboardPage = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const enterToAuctionsPage = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const enterToAuctionPage = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const auctionBet = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const enterToTendersPage = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const enterToTenderPage = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const tenderBet = (data) => {
	const url = "/api/analytics";
	return http.post(url, data);
};

export const flightSaved = (data) => {
	console.log(data);
	const url = "/api/analytics";
	return http.post(url, data);
};

// Comments for auctions

export const sendUpdatedComment = (customerId, commentId, body) => {
	const url = "/api/customers/" + customerId + "/comments/" + commentId;
	return http.put(url, body);
};

export const sendDestroyComment = (customerId, commentId) => {
	const url = "/api/customers/" + customerId + "/comments/" + commentId;
	return http.delete(url);
};

export const sendAuctionComment = (customerId, body) => {
	const url = `/api/customers/${customerId}/comments`;
	return http.post(url, body);
};

// Comments for tenders
export const sendTenderComment = (customerId, routeId, body) => {
	const url = `/api/customers/${customerId}/route_template_comments/${routeId}`;
	return http.put(url, body);
};

export const sendUpdatedTenderComment = (customerId, routeId, body) => {
	const url = `/api/customers/${customerId}/route_template_comments/${routeId}`;
	return http.put(url, body);
};

export const sendDestroyTenderComment = (customerId, routeId) => {
	const url = `/api/customers/${customerId}/route_template_comments/${routeId}`;
	return http.delete(url);
};
