export default {
  /**
   * Установка токена в localStorage
   * @param {object} token - объект токена
   */
  setToken(token) {
    window.localStorage.setItem('token', JSON.stringify(token));
  },
  /**
   * Получение токена из localStorage
   * @return {object} token - объект токена
   */
  getToken() {
    const data = JSON.parse(window.localStorage.getItem('token'));
    return data;
  },
  /**
   * Удаление токена из localStorage
   */
  destroyToken() {
    window.localStorage.removeItem('token');
  },
  /**
   * Проверка на то, есть ли токен в localStorage
   * @return {boolean}
   */
  isAuth() {
    const data = JSON.parse(window.localStorage.getItem('token'));
    return !!data;
  },
  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },
};
