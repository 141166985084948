import './polyfill';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router';
import store from './store';
import {Upload, Button, Icon, Select, Option, DatePicker} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';
import VueCookies from 'vue-cookies';
import VueGoogleCharts from 'vue-google-charts';

// todo
// cssVars()

const VueInputMask = require('vue-inputmask').default;

Vue.use(VueInputMask);
Vue.use(VueGoogleCharts);
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.use(VCalendar);

Vue.component(Upload.name, Upload);
Vue.component(Button.name, Button);
Vue.component(Icon.name, Icon);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(DatePicker.name, DatePicker);
locale.use(lang);

/* eslint-disable no-new */
new Vue({
  components: {App},
  template: '<App/>',
  router,
  store,
  VueCookies,
}).$mount('#app');

