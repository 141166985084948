import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import {signIn} from '../services/api';
import auth from '../services/Auth';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      isAuth: false,
      roles: ['ROLE_USER'],
      verified: false,
      verificationStatus: 0,
      ownerId: null,
      ownerType: null,
      company: null,
      username: null,
      id: null,
      INN: null,
    },
    refreshToken: null,
    filtersParams: {},
  },
  mutations: {
    setFilterParams(state, data) {
      const oldParamsString = localStorage.filtersParams;
      if (oldParamsString) {
        state.filtersParams = JSON.parse(oldParamsString);
      }
      state.filtersParams[data.componentName] = data.params;
      localStorage.filtersParams = JSON.stringify(state.filtersParams);
    },
    setToken(state, data) {
      state.user.isAuth = !!data;
      localStorage.token = data;
      VueCookies.set('BEARER', data);
    },
    setSession(state, data) {
      state.user.isAuth = !!data;
      localStorage.session = data;
      VueCookies.set('Session', data); 
    },
    setRefreshToken(state, data) {
      state.refreshToken = data;
      localStorage.refreshToken = data;
    },
    login(state, userData) {
      if (userData.ownerType === 'customer') {
        userData.verified = true;
      }
      state.user = {...state.user, ...userData};
    },
    rating(state, rating) {
      state.rating = rating;
    },
    logout(state) {
      delete localStorage.token;
      delete localStorage.refreshToken;
      VueCookies.remove('BEARER');
      VueCookies.remove('Session');
      state.user = Object.assign({}, {
        isAuth: false,
        roles: ['ROLE_USER'],
        verified: false,
        verificationStatus: 0,
        ownerId: null,
        ownerType: null,
        company: null,
        username: null,
        id: null,
        INN: null,
      });
      router.push({path: '/login'});
    },
  },
  actions: {
    async signIn({commit}, payload) {
      try {
        const res = await signIn(payload);
        if (!res.data.token) {
          throw new Error('Не получен токен!');
        }
        commit('setToken', res.data.token);
        commit('setRefreshToken', res.data.refreshToken);
        commit('login', auth.parseJwt(res.data.token));
        commit('setSession', res.data.session);
        router.push('/dashboard');
      } catch (err) {
        // todo Сделать нотификатор об ошибке
        // payload.vm.$notify.error({
        //   title: 'Ошибка',
        //   message: 'Ошибка сервера'
        // })
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    rating(state) {
      return state.rating;
    },
    isAuth(state) {
      return state.user.isAuth;
    },
    isCustomer(state) {
      return state.user.ownerType === 'customer';
    },
    isContractor(state) {
      return state.user.ownerType === 'contractor';
    },
    isAdmin(state) {
      return state.user.roles.includes('ROLE_ADMIN');
    },
    isAdminOrManager(state) {
      return state.user.roles.includes('ROLE_ADMIN') || state.user.roles.includes('ROLE_MANAGER');
    },
    getFilterParams: (state) => (componentName) => {
      if (!state.filtersParams[componentName] && localStorage.filtersParams) {
        const storedFilterParams = JSON.parse(localStorage.filtersParams);
        state.filtersParams[componentName] = storedFilterParams[componentName];
      }
      return state.filtersParams[componentName];
    },
    getRefreshToken: (state) => {
      return localStorage.refreshToken;
    },
  },
});
