<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Номер"
              >
                <b-form-input
                  v-model="filter.id"
                  class="form-control"
                  name="id"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('id')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select-clearable
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :multiple="true"
                  clearable
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Тип"
              >
                <el-select-clearable
                  v-model="filter.index"
                  class="form-control"
                  name="index"
                  placeholder=""
                  :multiple="true"
                  clearable
                  :loading="loading"
                  @input="updateFilter('index')"
                >
                  <el-option
                    v-for="item in customerRatingIndexes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Перевозчик"
              >
                <el-select-clearable
                  v-model="filter.contractors"
                  class="form-control"
                  name="contractor"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesContractorsList"
                  :loading="loading"
                  @input="updateFilter('contractors')"
                  @clear="customersRoutesContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата подачи от">
                <el-date-picker-input
                  v-model="filter.date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата подачи до">
                <el-date-picker-input
                  v-model="filter.date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_to')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Апелляции
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="createdOn"
            slot-scope="props"
          >
            {{ formatDatetime(props.row.createdOn) }}
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span v-if="props.row.status === 0">
              Новая
            </span>
            <span v-else-if="props.row.status === 1">
              На рассмотрении
            </span>
            <span
              v-else-if="props.row.status === 2"
              class="text-success"
            >
              Принята
            </span>
            <span
              v-else-if="props.row.status === 3"
              class="text-danger"
            >
              Отклонена
            </span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >

            <a
              v-b-tooltip.hover.bottom="'Редактировать'"
              class="fa fa-pencil fa-lg text-primary table-action-button"
              @click="openAppealModal(props.row)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="appealModal.show"
      v-if="appealModal.show"
      class="modal-warning"
      cancel-title="Отклонить"
      ok-variant="success"
      ok-title="Принять"
      :title="'Апелляция № '+appealModal.appeal.id"
      @ok="acceptAppeal()"
      @cancel="declineAppeal()"
    >
      <p>
        Статус:
        <span v-if="appealModal.appeal.status === 0">
          Новая
        </span>
        <span v-else-if="appealModal.appeal.status === 1">
          На рассмотрении
        </span>
        <span
          v-else-if="appealModal.appeal.status === 2"
          class="text-success"
        >
          Принята
        </span>
        <span
          v-else-if="appealModal.appeal.status === 3"
          class="text-danger"
        >
          Отклонена
        </span>
      </p>
      <p>
        Перевозчик:
        <a :href="'/customer/contractors-rating/id'+appealModal.appeal.contractor.id">
          {{ appealModal.appeal.contractor.name }}
        </a>
      </p>
      <p>Тип показателя: {{ appealModal.appeal.appealEvent.ratingIndexes[0].name }}</p>
      <p>
        Дата подачи: {{ formatDatetime(appealModal.appeal.createdOn) }}
      </p>
      <!-- <p>
        Период расчета:
        <a :href="'/customer/contractors-rating/id'+appealModal.appeal.contractor.id+'?detail='+appealModal.detailId">
          {{ formatDate(appealModal.appeal.indexValue.dateFrom) }}
          -
          {{ formatDate(appealModal.appeal.indexValue.dateTo) }}
        </a>
      </p> -->
      <div v-if="appealModal.appeal.appealEvent.movementPoint">
        <p>Код рейса: {{ appealModal.appeal.appealEvent.movementPoint.route.routeCode }}</p>
        <p>Отделение: {{ appealModal.appeal.appealEvent.movementPoint.department.name }}</p>
        <p>Плановое значение времени: {{ appealModal.appeal.appealEvent.movementPoint.planDateArrival }}</p>
        <p>Фактическое значение времени: {{ appealModal.appeal.appealEvent.movementPoint.factDateArrival }}</p>
        <p>
          Разница значений:
          <u>
            {{ Math.trunc((appealModal.appeal.appealEvent.movementPoint.interval / 60) / 60) }} час.
            {{ Math.trunc(appealModal.appeal.appealEvent.movementPoint.interval / 60) - Math.trunc(60 * Math.trunc((appealModal.appeal.appealEvent.movementPoint.interval / 60) / 60)) }} мин.
          </u>
        </p>
      </div>
      <b-form-group
        class="form-group-el-select"
        label="Комментарий Подрядчика"
        disabled
      >
        <b-form-textarea v-model="appealModal.appeal.comment" />
      </b-form-group>
      <b-form-group
        class="form-group-el-select"
        label="Комментарий"
        :disabled="appealModal.appeal.status === 3 || appealModal.appeal.status === 2"
      >
        <b-form-textarea v-model="appealModal.appeal.decisionComment" />
      </b-form-group>
      <template v-slot:modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          v-if="appealModal.appeal.status !== 2 && appealModal.appeal.status !== 3"
          size="sm"
          variant="success"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="ok()"
        >
          Принять
        </b-button>
        <b-button
          v-if="appealModal.appeal.status !== 3 && appealModal.appeal.status !== 2"
          size="sm"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="cancel()"
        >
          Отклонить
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          v-if="appealModal.appeal.status === 0"
          size="sm"
          variant="info"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="sendToProcessingAppeal()"
        >
          В обработку
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue'
import ElSelectClearable from '../../components/ElSelectClearable.vue'

import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import moment from 'moment';
import {
  customerAppealsList,
  customersRoutesContractorsList,
  customerRatingIndexes,
  customerAcceptAppeal, customerDeclineAppeal, customerAppealToProcessing,
  customerContractorRatingDetail,
} from '../../services/api';
import {queryToUrl, URLToQuery} from '../../services/http';
import {arrayStringToNumber} from '../../components/mixins/helpers';

Vue.use(ServerTable);

export default {
  name: 'CustomerAppealsList',
  components: {
    ServerTable,
    Event, ElSelectClearable, ElDatePickerInput
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      customerRatingIndexes: [],
      appealModal: {
        show: false,
        detailId: null,
        appeal: {
          decisionComment: '',
          indexValue: {index: {name: ''}},
          contractor: {
            name: '',
          },
        },
      },
      statuses: [
        {value: 0, text: 'Новые'},
        {value: 1, text: 'На рассмотрении'},
        {value: 2, text: 'Принятые'},
        {value: 3, text: 'Отклоненые'},
      ],
      contractors: [],
      ratingDetail: {},
      filter: {
        id: null,
        date_from: null,
        date_to: null,
        status: [0],
        index: [],
        contractors: [],
        page: 1,
        limit: 100,
      },
      loading: false,
      columns: [
        'id',
        'createdOn',
        'contractor.name',
        'indexValue.index.name',
        'status',
        'actions',
      ],
      options: {
        customFilters: [
          'id',
          'index',
          'contractors',
          'status',
          'date_from',
          'date_to',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customerAppealsList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'Номер',
          'createdOn': 'Дата подачи',
          'contractor.name': 'Перевозчик',
          'indexValue.index.name': 'Тип апелляции',
          'status': 'Статус',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 100,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  mounted() {
    this.customersRoutesContractorsList();
    this.getCustomerRatingIndexes();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    formatDatetime(date) {
      return moment(date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm');
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY');
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.status !== null) {
        res.status = this.filter.status;
      }
      if (this.filter.index !== null) {
        res.index = this.filter.index;
      }
      if (this.filter.contractors) {
        res.contractors = this.filter.contractors;
      }
      if (this.filter.date_from) {
        res.date_from = moment(this.filter.date_from).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.date_to) {
        res.date_to = moment(this.filter.date_to).format('YYYY-MM-DDTHH:mm');
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});

      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('status')) {
          params.status = arrayStringToNumber(params.status);
        }
        if (params.hasOwnProperty('index')) {
          params.index = arrayStringToNumber(params.index);
        }
        if (params.hasOwnProperty('contractors')) {
          params.contractors = arrayStringToNumber(params.contractors);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
    async customersRoutesContractorsList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersRoutesContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.contractors.length > 0) {
        this.customersRoutesSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedContractorsList() {
      this.loading = true;
      const selectedContractors = {};
      this.filter.contractors.map((item, index) => {
        selectedContractors['id[' + index + ']'] = item;
      });
      const response = await customersRoutesContractorsList(this.$store.state.user.ownerId, selectedContractors);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.id);
          this.contractors.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async getCustomerRatingIndexes(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customerRatingIndexes(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.customerRatingIndexes = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.index.length > 0) {
        this.getCustomerRatingIndexesSelected();
      }
      this.loading = false;
    },
    async getCustomerRatingIndexesSelected() {
      this.loading = true;
      const selectedContractors = {};
      this.filter.ratingIndexes.map((item, index) => {
        selectedContractors['id[' + index + ']'] = item;
      });
      const response = await customerRatingIndexes(this.$store.state.user.ownerId, selectedContractors);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.customerRatingIndexes = this.contractors.filter((x) => x.value !== item.id);
          this.customerRatingIndexes.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    openAppealModal(object) {
      console.log(object);
      this.appealModal.show = true;
      this.appealModal.appeal = object;
      this.appealModal.detailId = object.id;
    },
    async acceptAppeal() {
      const response = await customerAcceptAppeal(
        this.$store.state.user.ownerId,
        this.appealModal.appeal.id,
        this.appealModal.appeal,
      );
      if (response && response.status === 200) {
        this.showSuccess('Апелляция принята');
        this.$refs.table.refresh();
      }
    },
    async declineAppeal() {
      const response = await customerDeclineAppeal(
        this.$store.state.user.ownerId,
        this.appealModal.appeal.id,
        this.appealModal.appeal,
      );
      if (response && response.status === 200) {
        this.showSuccess('Апелляция отказана');
        this.$refs.table.refresh();
      }
    },
    async sendToProcessingAppeal() {
      const response = await customerAppealToProcessing(
        this.$store.state.user.ownerId,
        this.appealModal.appeal.id,
        this.appealModal.appeal,
      );
      if (response && response.status === 200) {
        this.showSuccess('Апелляция в обработку');
        this.$refs.table.refresh();
      }
    },
  },
};
</script>

<style scoped>

</style>
