<template>
  <b-card no-body>
    <b-card-header>
      Видеоуроки
      <div class="card-header-actions">
        <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
        <b-button
          :disabled="loading"
          variant="primary"
          size="sm"
          class="ml-1"
          @click="$refs.table.refresh()"
        >
          <i
            :class="{'fa-spin' : loading}"
            class="fa fa-refresh"
          />&nbsp;Обновить
        </b-button>
        <b-button
          v-if="$store.getters.isAdmin"
          variant="primary"
          size="sm"
          to="/customers/video-lessons/add"
          class="ml-2"
        >
          <i class="fa fa-plus" />&nbsp;Добавить
        </b-button>
      </div>
    </b-card-header>
    <b-card-body :class="{'loading' : loading}">
      <v-server-table
        id="dataTable"
        ref="table"
        :columns="columns"
        :options="options"
        :theme="theme"
        @loaded="loading = false"
      >
        <span
          slot="published"
          slot-scope="props"
        >
          <b-checkbox
            disabled
            :checked="props.row.published"
          />
        </span>
        <span
          slot="actions"
          slot-scope="props"
        >
          <a
            v-if="$store.getters.isAdmin"
            v-b-tooltip.hover.bottom="'Редактировать'"
            :href="'/customers/video-lessons/edit/id'+props.row.id"
            class="fa fa-pencil fa-lg text-primary table-action-button"
          />
          <a
            v-b-tooltip.hover.bottom="'Просмотреть'"
            :href="'/customers/video-lessons/id'+props.row.id"
            class="fa fa-eye fa-lg text-secondary table-action-button"
          />
          <a
            v-if="props.row.published"
            v-b-tooltip.hover.bottom="'Убрать публикацию'"
            class="fa fa-unlock fa-lg text-danger table-action-button"
            @click="confirmUnpublish(props.row.id)"
          />
          <a
            v-if="!props.row.published"
            v-b-tooltip.hover.bottom="'Опубликовать'"
            class="fa fa-lock fa-lg text-danger table-action-button"
            @click="confirmPublish(props.row.id)"
          />
          <a
            v-b-tooltip.hover.bottom="'Удалить'"
            class="fa fa-close fa-lg text-danger table-action-button"
            @click="confirmDelete(props.row.id)"
          />
        </span>
      </v-server-table>
    </b-card-body>
  </b-card>
</template>

<script>
import Vue from 'vue';
import {Event, ServerTable} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customerDeleteVideoLesson,
  customerPublishVideoLesson,
  customersUserBlock,
  customersUserUnblock,
  customerVideoLessonsList,
} from '../../services/api';
import moment from 'moment';

Vue.use(ServerTable);

Vue.directive('add-class-hover', {
  bind(el, binding, vnode) {
    const {value=''} = binding;
    el.addEventListener('mouseenter', ()=> {
      el.classList.add(value);
    });
    el.addEventListener('mouseleave', ()=> {
      el.classList.remove(value);
    });
  },
  unbind(el, binding, vnode) {
    el.removeEventListener('mouseenter');
    el.removeEventListener('mouseleave');
  },
});
export default {
  name: 'CustomerVideoLessonsList',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        name: null,
      },

      loading: false,
      columns: ['name', 'description', 'published', 'sortPosition', 'actions'],
      data: [],
      options: {
        customFilters: [],
        requestFunction: (data) => {
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customerVideoLessonsList( data);
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          name: 'Название',
          description: 'Описание',
          sortPosition: 'Позиция сортировки',
          published: 'Опубликован',
          actions: '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  methods: {
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    confirmUnpublish(lessonId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите убрать с публикации видео урок?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.unpublishLesson(lessonId);
          }
        });
    },
    async unpublishLesson(lessonId) {
      this.loading = true;
      const response = await customerPublishVideoLesson(lessonId, {published: false});
      if (response && response.status === 200) {
        this.showSuccess('Видеоурок снят с публикации');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmPublish(lessonId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите опубликовать урок?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.publishLesson(lessonId);
          }
        });
    },
    async publishLesson(lessonId) {
      this.loading = true;
      const response = await customerPublishVideoLesson(lessonId, {published: true});
      if (response && response.status === 200) {
        this.showSuccess('Видеоурок опубликован');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmDelete(lessonId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить урок?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteLesson(lessonId);
          }
        });
    },
    async deleteLesson(lessonId) {
      this.loading = true;
      const response = await customerDeleteVideoLesson(lessonId, {published: true});
      if (response && response.status === 200) {
        this.showSuccess('Видеоурок удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
