<script>
import {Bar, mixins} from 'vue-chartjs';
const {reactiveProp} = mixins;
export default {
  name: 'RatingChart',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          }],
      },
      reactive: false,
      onClick: this.handleClick,
      responsive: true,
      maintainAspectRatio: false,
    },
    );
  },
  methods: {
    handleClick(point, event) {
      const item = event[0];
      if (item) {
        this.$emit('index-select', item._index);
        // console.log(this.chartData);
        // console.log({
        //   index: item._index,
        // });
      }
    },
  },
};
</script>
