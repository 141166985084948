<template>
  <div v-if="appeals">
    <!-- <div v-for="(ratingIndexValue, indexKey) in ratingIndexValues"> -->
      <b-card
        v-for="(appeal, key) in appeals"
        :key="key"
        no-body
      >
        <b-card-header v-b-toggle="'key'+key">
          <b-row class="annotation-header">
            <b-col><h5>№ {{appeal.id}} от <u>{{ formatDate(appeal.createdOn) }}</u></h5></b-col>
            <b-col><h5>Событие: <u v-if="appeal.appealEvent.hasOwnProperty('movementPoint')">{{ appeal.appealEvent.ratingIndexes[0].name }}</u>
            <u v-if="appeal.appealEvent.hasOwnProperty('event')">{{ appeal.appealEvent.event.type == 0 ? 'ДТП' : 'Кража' }}</u>
            </h5></b-col>
            <b-col><h5 v-if="appeal.appealEvent.hasOwnProperty('event')">Рейс: <u>{{ appeal.appealEvent.event.route.routeCode }}</u></h5></b-col>
            <b-col v-if="appeal.status === 0">
              <h5>Статус: <u>Новая</u></h5>
            </b-col>
            <b-col v-else-if="appeal.status === 1">
              <h5>Статус: <u>На рассмотрении</u></h5>
            </b-col>
            <b-col v-else-if="appeal.status === 2" class="text-success">
              <h5>Статус: <u>Принята</u></h5>
            </b-col>
            <b-col v-else-if="appeal.status === 3" class="text-danger">
              <h5>Статус: <u>Отклонена</u></h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse
          :id="'key'+key"
          class="card-body"
        >
          <h5><b>Ваш коментарий:</b></h5>
          <p>{{ appeal.comment }}</p>
          <div v-if="appeal.decisionComment">
            <h5><b>Ответ заказчика:</b></h5>
            <p>{{ appeal.decisionComment }}</p>
          </div>
        </b-collapse>
      </b-card>
    <!-- </div> -->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ContractorRatingAppealsTable',
  props: ['ratingIndexValues', 'appeals'],
  data() {
    return {};
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
 .annotation-header{
   color: #23282c;
 }
 .card-header[aria-expanded]:hover{
   text-decoration: none;
 }
</style>
