export const arrayStringToNumber = (array) => {
  const res = array.map((item) => {
    return Number(item);
  });
  return res;
};

export const valueToValueWithVAT = (value) => {
  const res = (Number(value) * 1.2).toFixed(2);
  return res;
};

export const valueToValueWithoutVAT = (value) => {
  const res = (Number(value) / 1.2).toFixed(2);
  return res;
};

export const numberToRUFormat = (value) => {
  const res = new Intl.NumberFormat('ru-RU').format(value);
  return res;
};
