<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Рейтинг
      <span
        :class="{'rating-low': $store.state.rating <= 30, 'rating-mid': $store.state.rating >= 31 && $store.state.rating <= 49, 'rating-high': $store.state.rating >= 50}"
      >{{ $store.state.rating }}</span>
    </h4>
    <h5><span class="icon-chart" /> График изменения рейтинга</h5>
    &nbsp;
    <b-card-header class="d-flex justify-content-between">
      <h5>
        <b-button
          class="icon-minus"
          :disabled="ratingScopedLabels.length == 0"
          v-b-popover.hover.top="'Отдалить'"
          @click="zoomOut"
        />
        &nbsp;
        <b-button
          class="icon-plus"
          :disabled="chartData.labels.length == 1"
          v-b-popover.hover.top="'Приблизить'"
          @click="zoomIn"
        />
        &nbsp;
        <b-button
          class="icon-arrow-left"
          :disabled="ratingScopedLabels.length == 0 || currentPageNumber == 1"
          v-b-popover.hover.top="'Прокрутить назад'"
          @click="goPrevious"
        />
        &nbsp;
        <b-button
          class="icon-arrow-right"
          :disabled="ratingScopedLabels.length == 0 || currentPageNumber == pageMaxCount"
          v-b-popover.hover.top="'Прокрутить вперёд'"
          @click="goNext"
        />
        &nbsp;
      </h5>
      <h5>
        <el-date-range-input
          v-model="dateVal"
          type="daterange"
          align="right"
          start-placeholder="Начальная дата"
          end-placeholder="Конечная дата"
          format="dd.MM.yyyy"
          :picker-options="pickerOptions"
          @change="selectPeriodByDate(ratingPeriods.value, dateVal)"
        />
      </h5>
      <h5>
        <el-select v-model="ratingPeriods.value" @change="selectPeriod">
          <el-option
            v-for="item in ratingPeriods.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </h5>
    </b-card-header>
    <b-card>
      <div style="max-height: 500px">
        <rating-chart
          v-if="chartLoad"
          :chart-data="chartData"
          @index-select="reloadChartDetail"
          @scroll="zoomIn"
        />
      </div>
    </b-card>
    <b-tabs
      justified
      tabs
    >
      <b-tab
        title="Детализация рейтинга"
      >
        <b-row>
          <b-col class="rating-info-coll">
            <b>Период расчета рейтинга:</b> {{ formatDate(ratingDetail.dateFrom) +
              ' - ' + formatDate(ratingDetail.dateTo) }}
          </b-col>
          <b-col
            v-for="(item, index) in ratingDetail.ratingIndexValues"
            :key="index"
            class="rating-info-coll"
          >
            <b>{{ item.index.ratingIndex.name }}:</b>
            <span v-if="item.calculation.countNoDelayed">
              {{ item.calculation.countNoDelayed/item.calculation.countRoutes*100 }} %
            </span>
            <span v-else-if="item.calculation.countAuctions">
              {{ item.calculation.countAuctions }}
            </span>
            <span v-else>
              {{ item.calculation.countRoutes }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="rating-info-coll">
            <b> Итоговый рейтинг:</b> <span
              v-if="ratingDetail.rating"
              :class="{'rating-low': $store.state.rating <= 30, 'rating-mid': $store.state.rating >= 31 && $store.state.rating <= 49, 'rating-high': $store.state.rating >= 50}"
            > {{ ratingDetail.rating }}</span>
          </b-col>
        </b-row>
        <div v-if="ratingDetail.ratingIndexValues">
          <contractor-rating-detail-table
            v-if="ratingDetail.ratingIndexValues"
            :rating-index-values="ratingDetail.ratingIndexValues"
            :appeals-events="appealsEvents"
            :appeals="appeals"
          />
        </div>
      </b-tab>
      <b-tab
        title="Поданные апелляции"
      >
        <div v-if="appeals">
          <contractor-rating-appeals-table
            :rating-index-values="ratingDetail.ratingIndexValues"
            :appeals="appeals"
            @reload-detail="reloadDetail(ratingDetail.id)"
          />
        </div>
        <div v-else><h2>Поданные апелляции не найдены</h2></div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue';

import RatingChart from './RatingChart';
import {
  contractorRatingList,
  contractorRatingDetail,
  contractorRatingDetailById,
  ratingPeriods,
  contractorAppealsList,
  contractorEventsForAppeals,

} from '../../services/api';
import moment from 'moment';
import {ClientTable} from 'vue-tables-2';
import ContractorRatingDetailTable from './ContractorRatingDetailTable';
import ContractorRatingAppealsTable from './ContractorRatingAppealsTable';
import ElDateRangeInput from '@/components/ElDateRangeInput';

Vue.use(ClientTable);

export default {
  name: 'ContractorRating',
  components: {
    ContractorRatingAppealsTable,
    ContractorRatingDetailTable,
    RatingChart,
    ElDateRangeInput,
  },
  data() {
    return {
      cantGoPrevious: false,
      cantGoNext: true,
      currentPageNumber: 1,
      pageMaxCount: 0,
      appeals: [],
      appealsEvents: [],
      ratingList: [],
      ratingScopedLabels: [],
      ratingDetail: {},
      ratingData: {},
      periodDates: ['2020-12-14', '2020-12-21'],
      dateVal: null,
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      ratingPeriods: {
        options: [],
        value: '',
      },
      chartLoad: true,
      chartOptions: {
        reactive: true,
        onClick: this.handleClick,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            gridLines: {
              display: true,
            },
          }],
        },
      },
    };
  },
  computed: {
    chartData: function(scopedData) {
      const labels = [];
      const data = [];
      for (const [key, rating] of Object.entries(this.ratingData)) {
        labels.push(key);
        data.push(rating);
      }
      return {
        labels,
        datasets: [
          {
            label: 'Рейтинг',
            data,
          },
        ],
      };
    },
  },
  async mounted() {
    await this.getContractorRatingList();
    await this.getRatingDetail();
    await this.getRatingPeriods();
    await this.getContractorAppeals();
    await this.getEventsForAppeals();
    this.init();
  },
  methods: {
    init() {
      this.pageMaxCount = this.ratingList.length;
    },
    zoomIn() {
      this.ratingScopedLabels.push(this.chartData.labels.splice(this.chartData.datasets.length, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    zoomOut() {
      if (this.currentPageNumber > 1) {
        if (this.ratingScopedLabels.length > 1) {
          this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
        }
        this.chartData.labels.unshift(this.ratingScopedLabels.splice(this.currentPageNumber - 2, 1));
        this.currentPageNumber--;
      } else {
        this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
      }
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    goNext() {
      this.currentPageNumber++;
      this.ratingScopedLabels.unshift(this.chartData.labels.splice(0, 1));
      this.chartData.labels.push(this.ratingScopedLabels.splice(this.ratingScopedLabels.length - 1, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    goPrevious() {
      this.currentPageNumber--;
      this.ratingScopedLabels.push(this.chartData.labels.splice(this.chartData.labels.length - 1, 1));
      this.chartData.labels.unshift(this.ratingScopedLabels.splice(0, 1));
      this.chartLoad = false;
      this.$nextTick(() => {
        this.chartLoad = true;
      });
    },
    selectPeriod(id) {
      this.getContractorRatingList(id);
    },
    selectPeriodByDate(id, dateVal) {
      if (dateVal) {
        const dateFrom = this.formatDate(dateVal[0]);
        const dateTo = this.formatDate(dateVal[1]);
        this.getContractorRatingList(id, dateFrom, dateTo);
        this.getContractorAppeals(dateFrom, dateTo);
        this.getEventsForAppeals();
      }
    },
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
    reloadChartDetail(index) {
      this.reloadDetail(this.ratingList[index].id);
    },
    async reloadDetail(id) {
      const response = await contractorRatingDetailById(this.$store.state.user.ownerId, id);
      if (response && response.status === 200) {
        this.ratingDetail = response.data;
        // const appeals = [];
        // this.ratingDetail.ratingIndexValues.forEach((ratingIndexValue) => {
        //   ratingIndexValue.ratingIndexValueAppeals.forEach((appeal) => appeals.push(appeal));
        // });
        // this.appeals = appeals;
        const dateFrom = this.formatDate(this.ratingDetail.dateFrom);
        const dateTo = this.formatDate(this.ratingDetail.dateTo);
        this.getContractorAppeals(dateFrom, dateTo);
        this.getEventsForAppeals();
      }
    },
    async getContractorRatingList(periodId = 1, dateFrom, dateTo) {
      const params = {page: this.currentPageNumber};
      if (dateFrom) {
        params.dateFrom = dateFrom;
      }
      if (dateTo) {
        params.dateTo = dateTo;
      }
      if (this.dateVal) {
        params.dateFrom = moment(this.dateVal[0]).format('DD.MM.YYYY');
        params.dateTo = moment(this.dateVal[1]).format('DD.MM.YYYY');
      }
      const response = await contractorRatingList(
        this.$store.state.user.ownerId,
        params,
        periodId,
      );
      if (response && response.status === 200) {
        this.ratingList = [];
        this.ratingData = {};
        response.data.items.reverse().forEach((item) => {
          this.ratingList.push(item);
          const period = moment(item.dateFrom).format('DD.MM.YYYY') +
                            ' - ' + moment(item.dateTo).format('DD.MM.YYYY');
          this.$set(this.ratingData, period, item.rating);
        });
        if (this.currentPageNumber === 1) {
          this.cantGoNext = true;
        } else {
          this.cantGoNext = false;
        }
        if (response.data.totalCount - response.data.currentPageNumber * response.data.numItemsPerPage > 0) {
          this.cantGoPrevious = false;
        } else {
          this.cantGoPrevious = true;
        }
        this.currentPageNumber = response.data.currentPageNumber;
        this.init();
      }
    },
    async getRatingDetail() {
      const response = await contractorRatingDetail(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        this.ratingDetail = response.data;
        // const appeals = [];
        // this.ratingDetail.ratingIndexValues.forEach((ratingIndexValue) => {
        //   console.log(ratingIndexValue);
        //   ratingIndexValue.ratingIndexValueAppeals.forEach((appeal) => appeals.push(appeal));
        // });
        this.periodDates = [this.ratingDetail.dateFrom, this.ratingDetail.dateTo];
        // this.appeals = appeals;
      }
    },
    async getRatingPeriods() {
      const response = await ratingPeriods();
      if (response && response.status === 200) {
        this.ratingPeriods.options = response.data.items.map((item) => {
          return {value: item.id, text: item.name};
        });
        this.ratingPeriods.value = 1;
      }
    },
    async getContractorAppeals(dateFrom, dateTo) {
      const params = {};
      if (dateFrom) {
        params.dateFrom = dateFrom;
      }
      if (dateTo) {
        params.dateTo = dateTo;
      }
      const response = await contractorAppealsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.appeals = response.data.items;
      }
    },
    async getEventsForAppeals() {
      let response = [];
      response = await this.ratingDetail.ratingIndexValues.map((ratingIndexItem) => {
        let result = [];
        let rs = null;
        rs = contractorEventsForAppeals(
          this.$store.state.user.ownerId,
          ratingIndexItem.id,
        );
        rs.then(function(promiseResult) {
          result.push(promiseResult.data);
        });
        return result;
      });
      this.appealsEvents = response;
    },
  },
};
</script>

<style scoped>
.rating-info-coll {
  padding: 15px;
}
</style>
