<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        {{ ro.name }}
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Прикрепленный пользователь"
              >
                <el-select
                  v-model="form.userId"
                  class="form-control"
                  name="manager"
                  placeholder="Начните вводить название"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersUsersList"
                  :loading="loading"
                  @clear="customersUsersList"
                >
                  <el-option
                    v-for="item in users"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <div class="fixed-buttons">
              <b-button
                type="button"
                variant="secondary"
                class="mr-2"
                :class="{'loading' : loading}"
                @click="$router.go(-1)"
              >
                Назад
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                :disabled="loading"
                :class="{'loading' : loading}"
              >
                Сохранить
              </b-button>
            </div>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  customersUsersList,
  getCustomerRouteOwner,
  patchCustomerRouteOwner,
  routeOwnersList,
} from '../../services/api';

export default {
  name: 'STLEdit',
  props: ['stlId'],
  data: function() {
    return {
      loading: true,
      users: [],
      ro: {},
      form: {
        userId: null,
      },
    };
  },
  mounted() {
    // this.customersRouteRead(this.$store.state.user.ownerId, this.flightId);
    this.customersUsersList({});
    this.getRouteOwner();
  },
  methods: {
    async getRouteOwner() {
      this.loading = true;
      const response = await getCustomerRouteOwner(this.$store.state.user.ownerId, this.stlId);
      if (response && response.status === 200) {
        this.ro = response.data;
        if (this.ro.agent) {
          this.form.userId = this.ro.agent.id;
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      this.loading = true;
      if (!this.ro.agent) {
        this.ro.agent = {};
      }
      this.ro.agent.id = this.form.userId;
      const response = await patchCustomerRouteOwner(
        this.$store.state.user.ownerId,
        this.stlId,
        {agent: this.form.userId},
      );
      if (response && response.status === 200) {
        console.log('success');
      }
      this.loading = false;
    },
    async customersUsersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersUsersList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.users = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.fullname+` (${item.username})`,
          };
        });
      }
      if (this.form.userId) {
        // this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    // async customersRoutesSelectedManagersList() {
    //   this.loading = true;
    //   const selectedManagers = {};
    //   this.filter.manager.map((item, index) => {
    //     selectedManagers['id[' + index + ']'] = item;
    //   });
    //   const response = await routeOwnersList(selectedManagers);
    //   if (response && response.status === 200) {
    //     response.data.items.forEach((item) => {
    //       this.managers = this.managers.filter((x) => x.value !== item.id);
    //       this.managers.unshift({
    //         value: item.id,
    //         text: item.name,
    //       });
    //     });
    //   }
    //   this.loading = false;
    // },

  },
};
</script>

<style scoped>

</style>
