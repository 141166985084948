<template>
  <div>
    <v-client-table
      v-if="ratingIndexValues"
      id="appealTable"
      :columns="columns"
      :options="options"
      :theme="theme"
      :data="ratingIndexValues"
    >
      <template slot="child_row" slot-scope="props" class="child-row">
        <template v-for="appealEvent in ratingIndexValues.appealsEvents[props.index - 1]">
          <template v-if="appealEvent.items">
            <div v-for="appealEventItem in appealEvent.items" :key="appealEventItem.id" class="mt-3">
              <h4>Событие: <u v-if="appealEventItem.movementPoint">Опоздание</u>
                <u v-if="appealEventItem.event">{{ appealEventItem.event.type == 0 ? 'ДТП' : 'Кража' }}</u>
              </h4>
              <h5 v-if="appealEventItem.event">Код рейса: <u>{{ appealEventItem.event.route.routeCode }}</u></h5>
              <div v-if="appealEventItem.movementPoint" class="mt-3 mb-3">
                <h5>Код рейса: <u>{{ appealEventItem.movementPoint.route.routeCode }}</u></h5>
                <h5>Отделение: <u>{{ appealEventItem.movementPoint.department.name }}</u></h5>
                <h5>Плановое значение времени: <u>{{ appealEventItem.movementPoint.planDateArrival }}</u></h5>
                <h5>Фактическое значение времени: <u>{{ appealEventItem.movementPoint.factDateArrival }}</u></h5>
                <h5>Разница значений:
                  <u>{{ Math.trunc((appealEventItem.movementPoint.interval / 60) / 60) }} час.
                    {{ Math.trunc(appealEventItem.movementPoint.interval / 60) - Math.trunc(60 * Math.trunc((appealEventItem.movementPoint.interval / 60) / 60)) }} мин.
                  </u>
                </h5>
              </div>
              <h4 v-if="eventInAppeals(appealEventItem.id) && eventAppealStatus(appealEventItem.id) != 3">Апелляция по данному событию подана</h4>
              <h4 v-if="eventAppealStatus(appealEventItem.id) != null">
                Статус: <u v-if="eventAppealStatus(appealEventItem.id) == 0" class="appeal-new">Новая</u>
                <u v-if="eventAppealStatus(appealEventItem.id) == 1" class="appeal-in-process">В обработке</u>
                <u v-if="eventAppealStatus(appealEventItem.id) == 2" class="appeal-accepted">Принята</u>
                <u v-if="eventAppealStatus(appealEventItem.id) == 3" class="appeal-rejected">Апелляция отклонена</u>
              </h4>
              <h5 class="mt-3"><b>Дата события: <u>{{ appealEventItem.eventDate }}</u></b></h5>
              <b-form-group
                class="form-group-el-select mt-3"
                :label="eventInAppeals(appealEventItem.id) && eventAppealStatus(appealEventItem.id) != 3 ? '' : 'Комментарий'"
              >
                <b-form-textarea v-if="!eventInAppeals(appealEventItem.id) || (eventInAppeals(appealEventItem.id) && eventAppealStatus(appealEventItem.id) == 3)"
                  v-model="appealEventItem.comment" />
                <b-button
                  variant="success"
                  class="mt-3"
                  :disabled="eventInAppeals(appealEventItem.id) && eventAppealStatus(appealEventItem.id) != 3"
                  @click="sendAppeal(appealEventItem.id, appealEventItem.comment)"
                >
                  Подать апелляцию
                </b-button>
              </b-form-group>
              <hr>
            </div>
          </template>
          <template v-if="appealEvent.items.length == 0">
            <h4>События отсутствуют</h4>
          </template>
        </template>
      </template>

      <span
        slot="name"
        slot-scope="props"
      >
        {{ props.row.index.ratingIndex.name }}
        <span
          :id="'popover-reactive-'+props.row.id"
          class="el-icon-info"
        />
        <b-popover
          ref="popover"
          :target="'popover-reactive-'+props.row.id"
          triggers="hover"
          placement="auto"
          container="my-container"
        >
          <template v-slot:title>
            {{ props.row.index.ratingIndex.name }}
          </template>

          <div v-if="props.row.index.formula">
            <p><b>Расчет производится по формуле по формуле:</b></p>
            <p>{{ props.row.index.formula }}</p>
          </div>
          <div v-else-if="props.row.index.indexOptions">
            <rating-index-options-table
              :option-value-name="props.row.index.name"
              :rating-index-options="props.row.index.indexOptions.sort((a, b) => {return a.min - b.min;})"
            />
          </div>
        </b-popover>
      </span>
      <span
        slot="period"
        slot-scope="props"
      >
        {{ formatDate(props.row.dateFrom) +
          ' - ' + formatDate(props.row.dateTo) }}
      </span>
      <span
        slot="factor"
        slot-scope="props"
      >
        {{ props.row.index.koeff }}
      </span>
      <span
        slot="rating"
        slot-scope="props"
      >
        {{ props.row.rating }}
        <span v-if="props.row.bonus">
          <span class="text-success">(+{{ props.row.bonus }}) </span>
          <span
            v-b-popover.hover="{title:'Комментарий к начисленному бонусу:', content: props.row.bonusComment}"
            class="el-icon-info"
          />
        </span>
      </span>
      <span
        slot="result"
        slot-scope="props"
      >
        {{ ((props.row.rating + props.row.bonus)*props.row.index.koeff/totalKoeff).toFixed(2) }}
      </span>
      <span
        slot="actions"
        slot-scope="props"
      >
        <b-button
          variant="success"
          @click="showAppealModal(props.row)"
        >
          Подать апелляцию
        </b-button>
      </span>
    </v-client-table>
  </div>
</template>

<script>
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import moment from 'moment';
import RatingIndexOptionsTable from './RatingIndexOptionsTable';
import {contractorIndexValueAppeal} from '../../services/api';
import notifications from '../../components/mixins/notifications';

Vue.use(ClientTable);
export default {
  name: 'ContractorRatingDetailTable',
  components: {RatingIndexOptionsTable},
  mixins: [notifications],
  props: ['ratingIndexValues', 'appealsEvents', 'appeals'],
  data() {
    return {
      appealRow: {
        show: false,
        disabled: true,
        type: '',
        comment: '',
        indexValueId: null,
      },
      theme: 'bootstrap4',
      columns: ['name', 'period', 'rating', 'factor', 'result'],
      options: {
        headings: {
          'name': 'Название',
          'period': 'Период расчета',
          'rating': 'Количество баллов',
          'factor': 'Коэффициент уд. веса',
          'result': 'Итого баллов',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: false,
        texts: {
          count: '',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
    };
  },
  computed: {
    totalKoeff() {
      let summ = 0;
      this.ratingIndexValues.forEach((indexValue) => {
        if (indexValue.index.koeff >0) {
          summ += indexValue.index.koeff;
        }
      });
      return summ;
    },
    eventInAppeals() {
      return (id) => {
        let appealInAppeals = false;
        this.appeals.forEach((appeal) => {
          if (appeal.appealEvent.id == id) {
            appealInAppeals = true;
          }
        });
        return appealInAppeals;
      };
    },
    eventAppealStatus() {
      return (id) => {
        let appealStatus = null;
        this.appeals.forEach((appeal) => {
          if (appeal.appealEvent.id == id) {
            appealStatus = appeal.status;
          }
        });
        return appealStatus;
      };
    },
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
    async sendAppeal(id, value) {
      const response = await contractorIndexValueAppeal(
        this.$store.state.user.ownerId,
        id,
        {
          comment: value,
        },
      );
      if (response && response.status === 201) {
        this.showSuccess('Апелляция принята');
        this.$emit('reload-detail');
      }
    },
  },
  mounted() {
    this.ratingIndexValues.appealsEvents = [];
    this.ratingIndexValues.appealsEvents = this.appealsEvents;
    this.ratingIndexValues.appealsEvents.comment = '';
  },
  beforeUpdate() {
    this.ratingIndexValues.appealsEvents = [];
    this.ratingIndexValues.appealsEvents = this.appealsEvents;
    this.ratingIndexValues.appealsEvents.comment = '';
  },
};
</script>

<style lang="scss">
.child-row {
  height: 100%;
}

#appealTable {
  .VueTables__child-row-toggler--closed::before {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    content: "Подробнее";
    background-color: var(--green);
    color: white;
  }

  .VueTables__child-row-toggler--open::before {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    content: "Закрыть";
    background-color: var(--green);
    color: white;
  }
}
</style>
