import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import store from '../store';
import Auth from '../services/Auth';

// Containers
const DefaultContainer = () => import('../containers/DefaultContainer');

// Views
const AttorneysAdd = () => import('../views/Attorneys/AttorneysAdd');
const AttorneysEdit = () => import('../views/Attorneys/AttorneysEdit');
const AttorneysList = () => import('../views/Attorneys/AttorneysList');
const AttorneysView = () => import('../views/Attorneys/AttorneysView');
const AuctionStatistic = () => import( '../views/Statistic/AuctionStatistic');
const Confirm = () => import('../views/Confirm');
const ConfirmInvite = () => import('../views/ContractorUsers/ConfirmInvite');
const ContactForm = () => import('../views/ContactRequisites/ContactForm');
const ContactsForContractorEdit = () => import('../views/ContactRequisites/ContactsForContractorEdit');
const ContainerAdd = () => import('../views/Containers/ContainerAdd');
const ContainersList = () => import('../views/Containers/ContainersList');
const ContainerView = () => import('../views/Containers/ContainerView');
const ContractorAttorneysEdit = () => import('../views/Contractor/ContractorAttorneysEdit');
const ContractorAuctions = () => import('../views/Auctions/ContractorAuctions');
const ContractorAuctionsView = () => import('../views/Auctions/ContractorAuctionsView');
const ContractorContactsList = () => import('../views/ContactRequisites/ContractorContactsList');
const ContractorFlights = () => import('../views/Flights/ContractorFlights');
const ContractorFlightsEdit = () => import('../views/Flights/ContractorFlightsEdit');
const ContractorFlightsView = () => import('../views/Flights/ContractorFlightsView');
const ContractorInfo = () => import('../views/Contractor/ContractorInfo');
const ContractorList = () => import('../views/Contractor/ContractorList');
const ContractorListsList = () => import('../views/Contractor/ContractorLists/ContractorListsList');
const ContractorListsListAdd = () => import('../views/Contractor/ContractorLists/ContractorListsListAdd');
const ContractorListsListEdit = () => import('../views/Contractor/ContractorLists/ContractorListsListEdit');
const ContractorListsListCopy = () => import('../views/Contractor/ContractorLists/ContractorListsListCopy');
const ContractorListsListView = () => import('../views/Contractor/ContractorLists/ContractorListsListView');
const ContractorManual = () => import('../views/ContractorManual');
const ContractorTenders = () => import('../views/Tenders/ContractorTenders');
const ContractorTendersView = () => import('../views/Tenders/ContractorTendersView');
const ContractorUserView = () => import('../views/ContractorUsers/ContractorUserView');
const ContractorUsersList = () => import('../views/ContractorUsers/ContractorUsersList');
const ContractorUserInvite = () => import('../views/ContractorUsers/ContractorUserInvite');
const ContractorUserEdit = () => import('../views/ContractorUsers/ContractorUserEdit');
const CustomerAttorneysList = () => import('../views/Attorneys/CustomerAttorneysList');
const CustomerAuctions = () => import('../views/Auctions/CustomerAuctions');
const CustomerAuctionsEdit = () => import('../views/Auctions/CustomerAuctionsEdit');
const CustomerAuctionsView = () => import('../views/Auctions/CustomerAuctionsView');
const CustomerDimensions = () => import('../views/Dimensions/CustomerDimensions');
const CustomerDimensionView = () => import('../views/Dimensions/CustomerDimensionView');
const CustomerDimensionEdit = () => import('../views/Dimensions/CustomerDimensionEdit');
const CustomerDimensionsAdd = () => import('../views/Dimensions/CustomerDimensionsAdd');
const CustomerRouteWayDimensions = () => import('../views/Dimensions/CustomerRouteWayDimensions');
const CustomerRouteWayDimensionView = () => import('../views/Dimensions/CustomerRouteWayDimensionView');
const CustomerRouteWayDimensionEdit = () => import('../views/Dimensions/CustomerRouteWayDimensionEdit');
const CustomerRouteWayDimensionsCreate = () => import('../views/Dimensions/CustomerRouteWayDimensionsCreate');
const CustomerContractorRating = () => import('../views/Rating/CustomerContractorRating');
const CustomerDriverDraftsList = () => import('../views/Drivers/CustomerDriverDraftsList');
const CustomerDriverDraftsView = () => import('../views/Drivers/CustomerDriverDraftsView');
const CustomerDriversList = () => import('../views/Drivers/CustomerDriversList');
const CustomerDriversView = () => import('../views/Drivers/CustomerDriversView');
const CustomerFlights = () => import('../views/Flights/CustomerFlights');
const CustomerFlightsEdit = () => import('../views/Flights/CustomerFlightsEdit');
const CustomerFlightsView = () => import('../views/Flights/CustomerFlightsView');
const CustomersContactRequisitesView = () => import('../views/ContactRequisites/CustomersContactRequisitesView');
const CustomerTenders = () => import('../views/Tenders/CustomerTenders');
const CustomerTendersAdd = () => import('../views/Tenders/CustomerTendersAdd');
const CustomerTendersEdit = () => import('../views/Tenders/CustomerTendersEdit');
const CustomerTendersView = () => import('../views/Tenders/CustomerTendersView');
const CustomerTransportVehiclesList = () => import('../views/TransportVehicles/CustomerTransportVehiclesList');
const CustomerTransportVehiclesView = () => import('../views/TransportVehicles/CustomerTransportVehiclesView');
const CustomerUsersList = () => import('../views/CustomerUsers/CustomerUsersList');
const CustomerManual = () => import('../views/CustomerManual');
const CustomerUserAdd = () => import('../views/CustomerUsers/CustomerUserAdd');
const CustomerUserEdit = () => import('../views/CustomerUsers/CustomerUserEdit');
const CustomerUserView = () => import('../views/CustomerUsers/CustomerUserView');
const Dashboard = () => import('../views/Dashboard');
const DriversAdd = () => import('../views/Drivers/DriversAdd');
const DriversAddFirstStep = () => import('../views/Drivers/DriversAddFirstStep');
const DriversEdit = () => import('../views/Drivers/DriversEdit');
const DriversList = () => import('../views/Drivers/DriversList');
const DriversView = () => import('../views/Drivers/DriversView');
const DropPassword = () => import('../views/DropPassword');
const FileViewer = () => import('../views/FileViewer');
const FlightsCreate = () => import('../views/Flights/FlightsCreate');
const ForgotPassword = () => import('../views/ForgotPassword');
const Help = () => import('../views/Help');
const Login = () => import('../views/Login');
const Logout = () => import('../views/Logout');
const MyContactsList = () => import('../views/ContactRequisites/MyContactsList');
const Notifications = () => import('../views/Notifications/Notifications');
const Profile = () => import('../views/Profile');
const Page404 = () => import('../views/Page404');
const RatingTop = () => import( '../views/Rating/Top/RatingTop');
const RatingSettings = () => import( '../views/Rating/Settings/RatingSettings');
const Registration = () => import('../views/Registration');
const RegistrationSuccess = () => import('../views/RegistrationSuccess');
const ReportsAuctions = () => import('../views/Reports/ReportsAuctions');
const ReportsRoutes = () => import('../views/Reports/ReportsRoutes');
const ReportsTenders = () => import('../views/Reports/ReportsTenders');
const Requisites = () => import('../views/Requisites');
const SharedData = () => import('../views/SharedData');
const TransportationNamesEdit = () => import('../views/TransportationNames/TransportationNamesEdit');
const TransportVehiclesAdd = () => import('../views/TransportVehicles/TransportVehiclesAdd');
const TransportVehiclesEdit = () => import('../views/TransportVehicles/TransportVehiclesEdit');
const TransportVehiclesList = () => import('../views/TransportVehicles/TransportVehiclesList');
const TransportVehiclesView = () => import('../views/TransportVehicles/TransportVehiclesView');
const UserInfo = () => import('../views/User/UserInfo');
const UserSettings = () => import('../views/User/UserSettings');
const Verification = () => import('../views/Verification');
const VerificationContractorEdit = () => import('../views/ContactRequisites/VerificationContractorEdit');
const ExampleDocumentsConfiguration = () => import('../views/ExampleDocuments/ExampleDocumentsConfiguration');


// Middleware
import auth from './middleware/auth';
import guest from './middleware/guest';
import isAdmin from './middleware/isAdmin';
import isAdminOrManager from './middleware/isAdminOrManager';
import ownerContractor from './middleware/ownerContractor';
import ownerCustomer from './middleware/ownerCustomer';
import verified from './middleware/verified';
import middlewarePipeline from './middlewarePipeline';
import ContractorRating from '../views/Rating/ContractorRating';
import STLList from '../views/STLCustomerUsers/STLList';
import STLEdit from '../views/STLCustomerUsers/STLEdit';
import CustomerAppealsList from '../views/Rating/CustomerAppealsList';
import CustomerVideoLessonAdd from '@/views/VideoLessons/CustomerVideoLessonAdd';
import CustomerVideoLessonsList from '@/views/VideoLessons/CustomerVideoLessonsList';
import CustomerVideoLessonsView from '@/views/VideoLessons/CustomerVideoLessonsView';
import CustomerVideoLessonEdit from '@/views/VideoLessons/CustomerVideoLessonEdit';

Vue.use(VueRouter);

/**
 * Ленивая подгрузка компонентов
 * @param {string} component - название компонента
 */
const lazyLoad = (component) =>
  import(`../views/${component}`);// /* webpackChunkName: "[index]" */  // todo: error "[request]-[index]"
const isAuth = !!window.localStorage.getItem('token');

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '*',
      component: Page404,
    },
    {
      path: '/',
      // redirect: () => '/dashboard',
      redirect: () => isAuth ? `/dashboard` : `/login`,
      name: 'home',
      component: DefaultContainer,
      meta: {
        label: 'Главная',
        middleware: [guest],
      },
      children: [
        {
          path: 'my-rating',
          name: 'my-rating',
          component: ContractorRating,
          meta: {
            label: 'Рейтинг',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'attorneys',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'attorneys',
              meta: {
                label: 'Доверенности',
                middleware: [auth, verified, ownerContractor],
              },
              component: AttorneysList,
            },
            {
              path: 'add',
              name: 'add-attorneys',
              component: AttorneysAdd,
              meta:
                {
                  label: 'Добавление доверенности',
                  middleware: [auth, verified, ownerContractor, isAdminOrManager],
                },
            },
            {
              path: 'attorney/id:attorneyId',
              name: 'edit-attorneys',
              component: AttorneysEdit,
              props: true,
              meta: {
                label: 'Редактирование доверенности',
                middleware: [auth, verified, isAdmin, ownerContractor],
              },
            },
          ],
        },
        {
          path: 'help',
          name: 'help',
          component: Help,
          meta: {
            label: 'Помощь',
            middleware: [auth, verified],
          },
        },
        {
          path: '/route-way-dimensions/customer',
          name: 'customer-route-way-dimensions',
          component: CustomerRouteWayDimensions,
          props: true,
          meta: {
            label: 'Связи габаритов',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/route-way-dimensions/customer/view/id:dimensionId',
          name: 'customer-route-way-dimensions-view',
          component: CustomerRouteWayDimensionView,
          props: true,
          meta: {
            label: 'Просмотр связки габарита ТС',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/route-way-dimensions/customer/edit/id:dimensionId',
          name: 'customer-route-way-dimensions-edit',
          component: CustomerRouteWayDimensionEdit,
          props: true,
          meta: {
            label: 'Редактирование связки габарита ТС',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/route-way-dimensions/customer/add',
          name: 'customer-route-way-dimensions-add',
          component: CustomerRouteWayDimensionsCreate,
          props: true,
          meta: {
            label: 'Создание связки габарита ТС',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/dimensions/customer',
          name: 'customer-dimensions',
          component: CustomerDimensions,
          props: true,
          meta: {
            label: 'Габариты',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/dimensions/customer/view/id:dimensionId',
          name: 'customer-dimensions-view',
          component: CustomerDimensionView,
          props: true,
          meta: {
            label: 'Просмотр габарита ТС',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/dimensions/customer/edit/id:dimensionId',
          name: 'customer-dimensions-edit',
          component: CustomerDimensionEdit,
          props: true,
          meta: {
            label: 'Редактирование габарита ТС',
            middleware: [auth, verified, ownerCustomer, isAdmin],
          },
        },
        {
          path: '/dimensions/customer/add',
          name: 'customer-dimensions-add',
          component: CustomerDimensionsAdd,
          props: true,
          meta: {
            label: 'Создание габарита ТС',
            middleware: [auth, verified, ownerCustomer, isAdmin],
          },
        },
        {
          path: 'contractor-manual',
          name: 'contractor-manual',
          component: ContractorManual,
          meta: {
            label: 'Руководство пользователя',
            middleware: [auth, verified],
          },
        },
        {
          path: 'customer-manual',
          name: 'customer-manual',
          component: CustomerManual,
          meta: {
            label: 'Руководство пользователя',
            middleware: [auth, verified],
          },
        },
        {
          path: 'containers',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'containers',
              component: ContainersList,
              meta: {
                label: 'Контейнеры',
                middleware: [auth, verified, ownerContractor],
              },
            },
            {
              path: 'add',
              name: 'add-container',
              component: ContainerAdd,
              meta:
                {
                  label: 'Добавление контейнера',
                  middleware: [auth, verified, ownerContractor, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: '/customer/contractors-rating/id:contractorId',
          name: 'customerContractorRating',
          component: CustomerContractorRating,
          props: true,
          meta: {
            label: 'Рейтинг перевозчика',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/rating/top',
          name: 'ratingTop',
          component: RatingTop,
          props: true,
          meta: {
            label: 'Рейтинг перевозчика',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/rating/settings',
          name: 'ratingSettings',
          component: RatingSettings,
          props: true,
          meta: {
            label: 'Рейтинг перевозчика',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/rating/appeals',
          name: 'customerAppealsList',
          component: CustomerAppealsList,
          props: true,
          meta: {
            label: 'Апелляции перевозчиков',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/stl/id:stlId',
          name: 'stlEdit',
          component: STLEdit,
          props: true,
          meta: {
            label: 'Управление группами',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/stl',
          name: 'contractorSTLList',
          component: STLList,
          props: true,
          meta: {
            label: 'Управление группами',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/customer/contractors/id:contractorId',
          name: 'contractor',
          component: ContractorInfo,
          props: true,
          meta: {
            label: 'Перевозчик',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'auctions/contractor',
          name: 'contractor-auctions',
          component: ContractorAuctions,
          meta: {
            label: 'Мои аукционы',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'tenders/contractor',
          name: 'contractor-tenders',
          component: ContractorTenders,
          meta: {
            label: 'Мои тендеры',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'contractor-users',
          name: 'contractor-users',
          component: ContractorUsersList,
          meta: {
            label: 'Мои пользователи',
            middleware: [auth, verified, ownerContractor],
          },
          children: [
            {
              path: 'id:userId',
              name: 'edit-contractor-user',
              component: ContractorUserEdit,
              props: true,
              meta: {
                label: 'Обновление пользователя',
                middleware: [auth, verified, ownerCustomer, isAdmin],
              },
            },
            {
              path: 'invite',
              name: 'invite-contractor-user',
              component: ContractorUserInvite,
              meta: {
                label: 'Пригласить пользователя',
                middleware: [auth, verified, ownerContractor, isAdmin],
              },
            },
          ],
        },
        {
          path: 'contractors/id:contractorId/attorney/id:attorneyId/edit',
          name: 'contractors-edit-attorneys',
          component: ContractorAttorneysEdit,
          props: true,
          meta: {
            label: 'Редактирование доверенности',
            middleware: [auth, verified, isAdmin],
          },
        },
        {
          path: 'contractor-list',
          name: 'contractor-list',
          component: ContractorList,
          meta: {
            label: 'Перевозчики',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'contractors/id:contractorId/flight/id:flightId',
          name: 'contractor-view-flight',
          component: ContractorFlightsView,
          props: true,
          meta: {
            label: 'Просмотр рейса',
            middleware: [auth, verified],
          },
        },
        {
          path: 'contractors/id:contractorId/auction/id:auctionsId',
          name: 'contractor-view-auction',
          component: ContractorAuctionsView,
          props: true,
          meta: {
            label: 'Просмотр аукциона',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'contractors/id:contractorId/tender/id:tendersId',
          name: 'contractor-view-tender',
          component: ContractorTendersView,
          props: true,
          meta: {
            label: 'Просмотр тендера',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'attorneys/customer',
          name: 'customer-attorneys',
          component: CustomerAttorneysList,
          meta: {
            label: 'Доверенности',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'driver-drafts',
          name: 'customer-driver-drafts',
          component: CustomerDriverDraftsList,
          meta: {
            label: 'Черновики водителей',
            middleware: [auth, verified, ownerCustomer],
          },
          children: [
            {
              path: 'id:driverDraftId',
              name: 'customer-view-driver-draft',
              component: CustomerDriverDraftsView,
              props: true,
              meta:
                {
                  label: 'Просмотр черновика водителя',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'drivers/customer',
          name: 'customer-drivers',
          meta: {
            label: 'Водители',
            middleware: [auth, verified, ownerCustomer],
          },
          component: CustomerDriversList,
        },
        {
          path: 'transport-vehicles/customer',
          name: 'customer-transport-vehicles',
          meta: {
            label: 'Транспортные средства',
            middleware: [auth, verified, ownerCustomer],
          },
          component: CustomerTransportVehiclesList,
        },
        {
          path: 'auctions/customer',
          name: 'customer-auctions',
          component: CustomerAuctions,
          meta: {
            label: 'Мои аукционы',
            middleware: [auth, verified, ownerCustomer],
          },
          children: [
            {
              path: 'auction/id:auctionsId',
              name: 'customer-edit-auctions',
              component: CustomerAuctionsEdit,
              props: true,
              meta:
                {
                  label: 'Редактирование аукциона',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'flights/customer',
          name: 'customer-flights',
          component: CustomerFlights,
          meta: {
            label: 'Мои рейсы',
            middleware: [auth, verified, ownerCustomer],
          },
          children: [
            {
              path: 'flight/id:flightId',
              name: 'customer-edit-flight',
              component: CustomerFlightsEdit,
              props: true,
              meta:
                {
                  label: 'Редактирование рейса',
                  middleware: [auth, verified, isAdminOrManager],
                },
            },
            {
              path: 'add',
              name: 'customer-add-flight',
              component: FlightsCreate,
              meta:
                {
                  label: 'Создание рейса',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'tenders/customer',
          name: 'customer-tenders',
          component: CustomerTenders,
          meta: {
            label: 'Мои тендеры',
            middleware: [auth, verified, ownerCustomer],
          },
          children: [
            {
              path: 'tender/id:tendersId',
              name: 'customer-edit-tenders',
              component: CustomerTendersEdit,
              props: true,
              meta:
                {
                  label: 'Редактирование тендера',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
            {
              path: 'add',
              name: 'customer-add-tenders',
              component: CustomerTendersAdd,
              meta:
                {
                  label: 'Создание тендера',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'customer-users',
          name: 'customer-users',
          component: CustomerUsersList,
          meta: {
            label: 'Мои пользователи',
            middleware: [auth, verified, ownerCustomer],
          },
          children: [
            {
              path: 'invite',
              name: 'invite-customer-users',
              component: CustomerUserAdd,
              meta: {
                label: 'Пригласить пользователя',
                middleware: [auth, verified, ownerCustomer, isAdmin],
              },
            },
            {
              path: 'id:userId',
              name: 'edit-customer-users',
              component: CustomerUserEdit,
              props: true,
              meta: {
                label: 'Обновление пользователя',
                middleware: [auth, verified, ownerCustomer, isAdmin],
              },
            },
          ],
        },
        {
          path: 'customers/id:customerId/auction/id:auctionsId',
          name: 'customer-view-auction',
          component: CustomerAuctionsView,
          props: true,
          meta: {
            label: 'Просмотр аукциона',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'customers/id:customerId/flight/id:flightId',
          name: 'customer-view-flight',
          component: CustomerFlightsView,
          props: true,
          meta: {
            label: 'Просмотр рейса',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'customers/id:customerId/tender/id:tendersId',
          name: 'customer-view-tender',
          component: CustomerTendersView,
          props: true,
          meta: {
            label: 'Просмотр тендера',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'customers/id:customerId/contact-requisites/id:contactId',
          name: 'customer-view-contact-requisites',
          component: CustomersContactRequisitesView,
          props: true,
          meta: {
            label: 'Просмотр рейса',
            middleware: [auth, verified],
          },
        },
        {
          path: 'customers/id:customerId/users/id:userId',
          name: 'customer-view-user',
          component: CustomerUserView,
          props: true,
          meta: {
            label: 'Просмотр пользователя',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'contractors/id:customerId/users/id:userId',
          name: 'contractor-view-user',
          component: ContractorUserView,
          props: true,
          meta: {
            label: 'Просмотр пользователя',
            middleware: [auth, verified],
          },
        },
        {
          path: 'contact-requisites',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'contact-requisites',
              component: MyContactsList,
              meta: {
                label: 'Контактные реквизиты',
                middleware: [auth, verified, ownerCustomer],
              },
            },
            {
              path: 'add',
              name: 'add-contact-requisites',
              component: ContactForm,
              meta: {
                label: 'Создание контактных резвизитов',
                middleware: [auth, verified, ownerCustomer, isAdmin],
              },
            },
            {
              path: 'id:contactId',
              name: 'view-contact-requisites',
              component: ContactForm,
              props: true,
              meta: {
                label: 'Просмотр контактных резвизитов',
                middleware: [auth, verified, ownerCustomer],
              },
            },
            {
              path: 'contractor/id:contractorId',
              name: 'edit-contractor-contact-requisites',
              component: ContactsForContractorEdit,
              props: true,
              meta: {
                label: 'Обновление контактных резвизитов для перевозчика',
                middleware: [auth, verified, ownerCustomer, isAdmin],
              },
            },
          ],
        },
        {
          path: 'contractor-lists-list',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'contractor-lists-list',
              meta: {
                label: 'Списки перевозчиков',
                middleware: [auth, verified, ownerCustomer],
              },
              component: ContractorListsList,
            },
            {
              path: 'add',
              name: 'add-contractor-lists-list',
              component: ContractorListsListAdd,
              meta:
                {
                  label: 'Добавление списка подрядчиков',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
            {
              path: 'contractor-list/copy/id:contractorListId',
              name: 'copy-contractor-lists-list',
              component: ContractorListsListCopy,
              props: true,
              meta: {
                label: 'Копирования списка подрядчиков',
                middleware: [auth, verified, isAdmin, ownerCustomer],
              },
            },
            {
              path: 'contractor-list/id:contractorListId',
              name: 'edit-contractor-lists-list',
              component: ContractorListsListEdit,
              props: true,
              meta: {
                label: 'Редактирование списка подрядчиков',
                middleware: [auth, verified, isAdminOrManager],
              },
            },
          ],
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            label: 'Панель инструментов',
            middleware: [auth, verified],
          },
        },
        {
          path: 'drivers',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'drivers',
              meta: {
                label: 'Мои водители',
                middleware: [auth, verified, ownerContractor],
              },
              component: () => lazyLoad('Drivers/DriversList'),
              // Drivers/DriversList
            },
            {
              path: 'add',
              name: 'add-driver',
              component: DriversAddFirstStep,
              meta: {
                label: 'Добавление водителя',
                middleware: [auth, verified, ownerContractor, isAdminOrManager],
              },
            },
            {
              path: 'driver/id:driverId',
              name: 'edit-driver',
              component: DriversEdit,
              props: true,
              meta:
                {
                  label: 'Редактирование водителя',
                  middleware: [auth, verified, ownerContractor, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'transportation-names',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'transportation-names',
              meta: {
                label: 'Типы перевозок',
                middleware: [auth, verified, ownerCustomer],
              },
              component: () => lazyLoad('TransportationNames/TransportationNamesList'),
            },
            {
              path: 'id:transportationNameId',
              name: 'edit-transportation-name',
              component: TransportationNamesEdit,
              props: true,
              meta:
                {
                  label: 'Редактирование типа перевозки',
                  middleware: [auth, verified, ownerCustomer, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: 'verification/contractor/id:contractorId',
          name: 'edit-contractor-verification-status',
          component: VerificationContractorEdit,
          props: true,
          meta: {
            label: 'Обновление статуса верификации перевозчика',
            middleware: [auth, verified, ownerCustomer, isAdmin],
          },
        },
        {
          path: 'flights/contractor',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'contractor-flights',
              meta: {
                label: 'Мои рейсы',
                middleware: [auth, verified, ownerContractor],
              },
              component: () => lazyLoad('Flights/ContractorFlights'),
            },
            {
              path: 'flight/id:flightId',
              name: 'contractor-edit-flight',
              component: () => lazyLoad('Flights/ContractorFlightsEdit'),
              props: true,
              meta:
                {
                  label: 'Редактирование рейса',
                  middleware: [auth, verified, isAdminOrManager],
                },
            },
          ],
        },
        {
          path: '/files/:fileGuid',
          name: 'file-viewer',
          component: FileViewer,
          props: true,
          meta:
            {
              label: 'Просмотр Файла',
              middleware: [auth],
            },
        },
        {
          path: 'my-profile',
          name: 'my-profile',
          component: UserInfo,
          meta: {
            label: 'Мой профиль',
            middleware: [auth, verified],
          },
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: Notifications,
          meta: {
            label: 'Уведомления',
            middleware: [auth],
          },
        },
        {
          path: 'profile',
          name: 'profile',
          component: Profile,
          meta: {
            label: 'Профиль компании',
            middleware: [auth, verified, ownerContractor],
          },
        },
        {
          path: 'transport-vehicles',
          component: () => lazyLoad('MainRouteView'),
          children: [
            {
              path: '',
              name: 'transport-vehicles',
              meta: {
                label: 'Транспортные средства',
                middleware: [auth, verified, ownerContractor],
              },
              component: TransportVehiclesList,
            },
            {
              path: 'add',
              name: 'add-transport-vehicles',
              component: TransportVehiclesAdd,
              meta:
                {
                  label: 'Добавление транспортного средства',
                  middleware: [auth, verified, ownerContractor, isAdminOrManager],
                },
            },
            {
              path: 'vehicle/id:transportVehiclesId',
              name: 'edit-transport-vehicles',
              component: TransportVehiclesEdit,
              props: true,
              meta: {
                label: 'Редактирование транспортного средства',
                middleware: [auth, verified, isAdmin],
              },
            },
          ],
        },
        {
          path: '/user-settings',
          name: 'user-settings',
          component: UserSettings,
          meta: {
            label: 'Настройки',
            middleware: [auth],
          },
        },
        {
          path: '/customer/contractor-lists/id:contractorListId',
          name: 'view-contractor-lists-list',
          component: ContractorListsListView,
          props: true,
          meta: {
            label: 'Список перевозчиков',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'contractors/id:contractorId/drivers/id:driverId',
          name: 'view-contractor-driver',
          component: DriversView,
          props: true,
          meta: {
            label: 'Просмотр водителя',
            middleware: [auth, verified],
          },
        },
        {
          path: 'customers/drivers/id:driverId',
          name: 'view-customer-driver',
          component: CustomerDriversView,
          props: true,
          meta: {
            label: 'Просмотр водителя',
            middleware: [auth, verified],
          },
        },
        {
          path: 'contractors/id:contractorId/vehicle/id:transportVehiclesId',
          name: 'view-vehicle',
          component: TransportVehiclesView,
          props: true,
          meta: {
            label: 'Просмотр транспортного средства',
            middleware: [auth, verified],
          },
        },
        {
          path: 'customers/transport-vehicle/id:transportVehiclesId',
          name: 'view-customer-vehicle',
          component: CustomerTransportVehiclesView,
          props: true,
          meta: {
            label: 'Просмотр транспортного средства',
            middleware: [auth, verified],
          },
        },
        {
          path: 'contractors/id:contractorId/containers/id:containerId',
          name: 'view-container',
          component: ContainerView,
          props: true,
          meta: {
            label: 'Просмотр контейнера',
            middleware: [auth, verified],
          },
        },
        {
          path: 'contractors/id:contractorId/attorney/id:attorneyId',
          name: 'view-attorney',
          component: AttorneysView,
          props: true,
          meta: {
            label: 'Просмотр доверенности',
            middleware: [auth, verified],
          },
        },
        {
          path: '/statistic',
          name: 'statistic',
          component: AuctionStatistic,
          meta: {
            label: 'Статистика по аукционам',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: 'reports/auctions',
          name: 'auctions-reports',
          component: ReportsAuctions,
          props: true,
          meta: {
            label: 'Отчет по аукционам',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'reports/routes',
          name: 'routes-reports',
          component: ReportsRoutes,
          props: true,
          meta: {
            label: 'Отчет по рейсам',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: 'reports/tenders',
          name: 'tenders-reports',
          component: ReportsTenders,
          props: true,
          meta: {
            label: 'Отчет по тендерам',
            middleware: [auth, verified, ownerCustomer],
          },
        },
        {
          path: '/contractors/video-lessons',
          name: 'contractors-video-lessons',
          component: () => lazyLoad('VideoLessons/ContractorVideoLessonsList'),
          meta: {
            label: 'Видеоуроки',
            middleware: [auth, ownerContractor],
          },
        },
        {
          path: '/customers/video-lessons',
          name: 'customers-video-lessons',
          component: () => lazyLoad('VideoLessons/CustomerVideoLessonsList'),
          meta: {
            label: 'Видеоуроки',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: '/customers/video-lessons/id:videoLessonId',
          name: 'customers-video-lessons-view',
          component: () => lazyLoad('VideoLessons/CustomerVideoLessonsView'),
          props: true,
          meta: {
            label: 'Видеоуроки',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: '/customers/video-lessons/edit/id:videoLessonId',
          name: 'customers-video-lessons-edit',
          component: () => lazyLoad('VideoLessons/CustomerVideoLessonEdit'),
          props: true,
          meta: {
            label: 'Редактирование видеоурока',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: '/customers/video-lessons/add',
          name: 'customers-video-lessons-add',
          component: () => lazyLoad('VideoLessons/CustomerVideoLessonAdd'),
          meta: {
            label: 'Добавление видеоурока',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: '/auction-plan/add',
          name: 'auction-plan-add',
          component: () => lazyLoad('AuctionPlan/AuctionPlanCreate'),
          meta: {
            label: 'Добавление плана аукциона',
            middleware: [auth, ownerCustomer, isAdmin],
          },
        },
        {
          path: '/auction-plan',
          name: 'auction-plan-view',
          component: () => lazyLoad('AuctionPlan/AuctionPlanView'),
          meta: {
            label: 'Планы аукциона',
            middleware: [auth, ownerCustomer],
          },
        },
        {
          path: 'example-documents-configuration',
          name: 'example-documents-configuration',
          component: ExampleDocumentsConfiguration,
          props: true,
          meta: {
            label: 'Настройки примеров файлов',
            middleware: [auth, ownerCustomer, isAdmin],
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        label: 'Вход',
        middleware: [guest],
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        label: 'Выход',
        middleware: [auth],
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        label: 'Забыли пароль',
        middleware: [guest],
      },
    },
    {
      path: '/drop-password',
      name: 'drop-password',
      component: DropPassword,
      meta: {
        label: 'Сброс пароля',
        middleware: [guest],
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: Registration,
      meta: {
        label: 'Регистрация',
        middleware: [guest],
      },
    },
    {
      path: '/registration-success',
      name: 'registration-success',
      component: RegistrationSuccess,
      meta: {
        label: 'Регистрация прошла успешно',
        middleware: [guest],
      },
    },
    {
      path: '/activation/email',
      name: 'email-activation',
      component: Confirm,
      meta: {
        label: 'Активация электронной почты',
        middleware: [guest],
      },
    },
    {
      path: '/activation/invitation',
      name: 'invitation-activation',
      component: ConfirmInvite,
      meta: {
        label: 'Активация приглашения',
        middleware: [guest],
      },
    },
    {
      path: '/verification',
      name: 'verification',
      component: Verification,
      meta: {
        label: 'Верификация',
        middleware: [auth, ownerContractor],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  } else {
    const middleware = to.meta.middleware;
    const context = {
      to,
      from,
      next,
      store,
    };

    if (!!localStorage.token) {
      let userData = Auth.parseJwt(localStorage.token);
      const todayDate = Math.round(new Date().getTime() / 1000);
      if (todayDate > userData.exp) {
        axios
          .get(process.env.VUE_APP_API_URL+'/api/login/check', {
            params: {
              refreshToken: localStorage.refreshToken,
            },
          })
          .then((response) => {
            store.commit('setToken', response.data.token);
            store.commit('setRefreshToken', response.data.refreshToken);
            userData = Auth.parseJwt(response.data.token);
            if (userData.ownerType === 'contractor') {
              axios
                .get(process.env.VUE_APP_API_URL + '/api/contractors/' + userData.ownerId,
                  {headers: {'Authorization': 'bearer ' + localStorage.token}},
                )
                .then((response) => {
                  userData.verified = response.data.contractor.active;
                  store.commit('rating', response.data.rating.rating);
                  store.commit('login', userData);
                  return middleware[0]({
                    ...context,
                    next: middlewarePipeline(context, middleware, 1),
                  });
                })
                .catch((error) => {
                  store.commit('logout');
                  return next({
                    name: 'login',
                  });
                });
            }
            return middleware[0]({
              ...context,
              next: middlewarePipeline(context, middleware, 1),
            });
          })
          .catch((error) => {
            store.commit('logout');
            return next({
              name: 'login',
            });
          });
      } else {
        store.commit('setToken', localStorage.token);
        userData = Auth.parseJwt(localStorage.token);
        if (userData.ownerType === 'contractor') {
          axios
            .get(
              process.env.VUE_APP_API_URL+'/api/contractors/' + userData.ownerId,
              {headers: {'Authorization': 'bearer ' + localStorage.token}},
            )
            .then((response) => {
              userData.verified = response.data.contractor.active;
              userData.verificationStatus = response.data.verificationStatus;
              userData.company = response.data.contractor.name;
              userData.INN = response.data.contractor.INN;
              store.commit('login', userData);
              if (response.data.rating && response.data.rating.rating) {
                store.commit('rating', response.data.rating.rating);
              }
              return middleware[0]({
                ...context,
                next: middlewarePipeline(context, middleware, 1),
              });
            })
            .catch((error) => {
              store.commit('logout');
              return next({
                name: 'login',
              });
            });
        } else {
          userData.verified = true;
          store.commit('login', userData);
          return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1),
          });
        }
      }
    } else {
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
      });
    }
  }
});
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});


export default router;
