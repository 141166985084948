<script>
import Vue from 'vue';
import {DatePicker} from 'element-ui';
import moment from 'moment';

const ElDateRangeInput = {
  name: 'ElDateRangeInput',
  extends: DatePicker,
  data() {
    return {
      userDataDateStart: '',
      userDataDateEnd: '',
    };
  },
  methods: {
    inputDate() {
      // Check preg match
      let matchedStartDateString = this.userInput[0];
      let matchedEndDateString = this.userInput[1];

      if (matchedStartDateString != null) { 
        matchedStartDateString = matchedStartDateString.replace(/^[a-zA-Zа-яА-Яё]+/g, '');
        Vue.set(this.userInput, 0, matchedStartDateString);
      }
      if (matchedEndDateString != null) { 
        matchedEndDateString = matchedEndDateString.replace(/^[a-zA-Zа-яА-Яё]+/g, '');
        Vue.set(this.userInput, 1, matchedEndDateString);
      }
      // Switch for first field
      if (matchedStartDateString && matchedStartDateString.length > 0) {
        switch (matchedStartDateString.length) {
        case 2:
          // Day validation
          const day = Number(matchedStartDateString);
          (day > 31) ? matchedStartDateString = matchedStartDateString.slice(0, 1) : (matchedStartDateString.indexOf('.') == -1) && (matchedStartDateString += '.');
          this.userDataDateStart = matchedStartDateString;
          Vue.set(this.userInput, 0, matchedStartDateString);
          break;
        case 5:
          // Month validation
          const month = Number(matchedStartDateString.slice(3, 5));
          (month > 12) ? matchedStartDateString = matchedStartDateString.slice(0, 4) : ((matchedStartDateString.match(/.+/g) || []).length == 1) && (matchedStartDateString += '.');
          this.userDataDateStart = matchedStartDateString;
          Vue.set(this.userInput, 0, matchedStartDateString);
          break;
        case 10:
          // Final validation
          this.userDataDateStart = matchedStartDateString;
          Vue.set(this.userInput, 0, matchedStartDateString);
        default:
          break;
        }
      }
      // Switch for second field
      if (matchedEndDateString && matchedEndDateString.length > 0) {
        switch (matchedEndDateString.length) {
        case 2:
          // Day validation
          const day = Number(matchedEndDateString);
          (day > 31) ? matchedEndDateString = matchedEndDateString.slice(0, 1) : (matchedEndDateString.indexOf('.') == -1) && (matchedEndDateString += '.');
          this.userDataDateEnd = matchedEndDateString;
          Vue.set(this.userInput, 1, matchedEndDateString);
          break;
        case 5:
          // Month validation
          const month = Number(matchedEndDateString.slice(3, 5));
          (month > 12) ? matchedEndDateString = matchedEndDateString.slice(0, 4) : ((matchedEndDateString.match(/.+/g) || []).length == 1) && (matchedEndDateString += '.');
          this.userDataDateEnd = matchedEndDateString;
          Vue.set(this.userInput, 1, matchedEndDateString);
          break;
        case 10:
          // Final validation
          this.userDataDateEnd = matchedEndDateString;
          Vue.set(this.userInput, 1, matchedEndDateString);
        default:
          break;
        }
      }
      if (matchedStartDateString && matchedStartDateString.length > 10) {
        matchedStartDateString = matchedStartDateString.slice(10, 0);
        matchedStartDateString = this.userDataDateStart;
        Vue.set(this.userInput, 0, matchedStartDateString);
      }
      if (matchedEndDateString && matchedEndDateString.length > 10) {
        matchedEndDateString = matchedEndDateString.slice(10, 0);
        matchedEndDateString = this.userDataDateEnd;
        Vue.set(this.userInput, 1, matchedEndDateString);
      }
    },
    formatDate() {
      (this.userDataDateStart.length == 10) && ((this.userDataDateStart = moment(this.userDataDateStart, 'DD.MM.YY').format('DD.MM.YYYY')));
      (this.userDataDateEnd.length == 10) && ((this.userDataDateEnd = moment(this.userDataDateEnd, 'DD.MM.YY').format('DD.MM.YYYY')));
    },
    setDate() {
      Vue.set(this.userInput, 0, this.userDataDateStart);
      Vue.set(this.userInput, 1, this.userDataDateEnd);
    },
    clearDate(e) {
      const matchedStartDateString = this.userInput[0];
      const matchedEndDateString = this.userInput[1];

      (e.key === 'Backspace' && this.userInput[0] != null) && (Vue.set(this.userInput, 0, matchedStartDateString));
      (e.key === 'Backspace' && this.userInput[1] != null) && (Vue.set(this.userInput, 1, matchedEndDateString));
    },
  },
  mounted() {
    this.$el.addEventListener('input', this.inputDate);
    this.$el.addEventListener('change', this.formatDate);
    this.$el.addEventListener('blur', this.setDate);
    this.$el.addEventListener('keyup', this.clearDate);
  },
};

export default ElDateRangeInput;
</script>
